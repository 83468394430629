import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts';
import dayjs from 'dayjs';
import { SensorReadingChartType } from '../../api/util/apiTypes';

interface RawData {
  id: string;
  sensor: string;
  value: number;
  created_at: string;
}

interface AggregatedData {
  bucket: string;
  min_val: number;
  max_val: number;
  avg_val: number;
}

interface SensorChartProps {
  data: SensorReadingChartType;
  granularity: string;
}

const SensorChart: React.FC<SensorChartProps> = ({ data, granularity }) => {
  // 1) Format RAW data
  const formattedRawData =
    data.type === 'raw'
      ? data.data.map((item) => {
        const raw = item as RawData;
        return {
          // unify to "datetime"
          datetime: dayjs(raw.created_at).format('HH:mm:ss'),
          value: raw.value,
        };
      })
      : [];

  // 2) Format AGGREGATED data
  const formattedAggregatedData =
    data.type === 'aggregated'
      ? data.data.map((item) => {
        const agg = item as AggregatedData;
        const aggregatedData = {
          min: agg.min_val,
          max: agg.max_val,
          avg: agg.avg_val,
          datetime: dayjs(agg.bucket).format('MMM DD HH:mm'),
        };
        aggregatedData.datetime =
            granularity == 'hourly'
              ? dayjs(agg.bucket).format('MMM DD HH:mm')
              : dayjs(agg.bucket).format('MMM DD');
        return aggregatedData;
      })
      : [];

  // 3) Decide which array to pass to the chart
  const chartData =
    data.type === 'raw' ? formattedRawData : formattedAggregatedData;

  return (
    <div className="w-full bg-white rounded-lg shadow p-4">
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          {/* 
            Because we unified both raw & aggregated 'time' fields under 
            'datetime', we can always use dataKey="datetime" 
          */}
          <XAxis dataKey="datetime" />
          <YAxis />
          <Tooltip />

          {/* 
            For raw data:  dataKey='value'
            For aggregated: dataKey='avg'
          */}
          <Line
            type="monotone"
            dataKey={data.type === 'raw' ? 'value' : 'avg'}
            stroke="#82ca9d"
            name={data.type === 'raw' ? 'Value' : 'Average'}
            strokeWidth={2}
          />

          {/* 
            If we are dealing with aggregated data, 
            show min and max lines as well. 
          */}
          {data.type === 'aggregated' && (
            <>
              <Line
                type="monotone"
                dataKey="min"
                stroke="#00bfff"
                name="Min"
                strokeWidth={2}
              />
              <Line
                type="monotone"
                dataKey="max"
                stroke="#8884d8"
                name="Max"
                strokeWidth={2}
              />
            </>
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SensorChart;
