import { useAppDispatch } from '../store/hooks';
import { toggleSidebar } from '../features/sidebar/sidebarSlice';
import { useLocation } from 'react-router-dom';

const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  
  // Get page title based on current path
  const getPageTitle = (): string => {
    const path = location.pathname;
    
    if (path === '/') return 'Dashboard';
    if (path.startsWith('/group')) return 'Groups';
    if (path.startsWith('/device')) return 'Devices';
    if (path.startsWith('/sensor')) return 'Sensors';
    if (path.startsWith('/rule')) return 'Rules';
    if (path.startsWith('/tech-support')) return 'Tech Support';
    
    // Default to pathname if no match
    return path.split('/')[1].charAt(0).toUpperCase() + path.split('/')[1].slice(1) || 'Dashboard';
  };
  
  return (
    <div className="bg-white shadow-sm border-b border-slate-200 px-4 py-3 flex items-center">
      <button
        onClick={() => dispatch(toggleSidebar())}
        className="md:hidden text-slate-600 hover:text-indigo-600 transition-colors p-1.5 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500/50"
        aria-label="Toggle sidebar"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </button>
      
      <div className="flex-grow flex justify-center md:justify-start md:ml-4">
        <h1 className="text-lg font-medium text-slate-800">{getPageTitle()}</h1>
      </div>
      
      <div className="w-10 md:hidden">
        {/* Empty div for balanced layout on mobile */}
      </div>
    </div>
  );
};

export default Header;
