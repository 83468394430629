import { useMutation } from '@tanstack/react-query';
import { verifyEmailApi } from '../../api/user/userApi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Spinner from '../Spinner';
import { useEffect, useState } from 'react';

const VerifyEmail: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  const token = searchParams.get('token');

  const { mutate, isPending } = useMutation({
    mutationFn: verifyEmailApi,
    onSuccess: () => {
      console.log('Email verified successfully');
      setMessage('Email verified successfully!');
      setIsError(false);
    },
    onError: (error) => {
      setMessage(error.message);
      setIsError(true);
    },
  });

  useEffect(() => {
    if (token) {
      mutate({ token });
    } else {
      setMessage('Invalid or missing token!');
      setIsError(true);
    }
  }, [token, mutate]);

  if (isPending) return <Spinner />;

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="p-8 bg-white shadow-md rounded-lg text-center">
        <h2
          className={`text-lg font-medium mb-4 ${isError ? 'text-red-600' : ''}`}
        >
          {message}
        </h2>
        <button
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
          onClick={() => navigate('/login')}
        >
          Go to Login
        </button>
      </div>
    </div>
  );
};

export default VerifyEmail;
