import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../store/hooks';
import { showNotification } from '../../features/notificationSlice';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Spinner from '../Spinner';
import { CreateTriggerType } from '../../api/util/apiTypes';
import {
  createTrigger,
  getTrigger,
  updateTrigger,
} from '../../api/rule/triggerApi';
import { listSwitch } from '../../api/switch/swicthApi';

const updateTriggerObject = (obj: CreateTriggerType) => {
  const { trigger_type, ...rest } = obj;

  if (trigger_type === 'switch') {
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    const { email, ...updatedObj } = rest;
    return { ...updatedObj, trigger_type };
  } else if (trigger_type === 'email') {
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    const { switch: switchValue, action, ...updatedObj } = rest;
    return { ...updatedObj, trigger_type };
  }

  return obj; // Return the object as is if trigger_type is neither 'switch' nor 'email'
};

const TriggerForm: React.FC = () => {
  const { ruleId, triggerId } = useParams<{
    ruleId: string;
    triggerId: string;
  }>();

  const [edit, setEdit] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const showNotificationHandler = (type: 'success' | 'error', message: string) => {
    dispatch(
      showNotification({ type, message, duration: 3000 })
    );
  };

  const {
    data: triggerData,
    isLoading: isLoadingTrigger,
    isError: isErrorTrigger,
    error: triggerError,
  } = useQuery({
    queryFn: () => getTrigger(triggerId ? triggerId : ''),
    queryKey: ['trigger', triggerId],
    enabled: !!triggerId,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (variables: { triggerData: object; triggerId?: string }) => {
      if (edit && variables.triggerId) {
        return updateTrigger(variables.triggerData, variables.triggerId);
      } else {
        return createTrigger(variables.triggerData);
      }
    },
    onSuccess: () => {
      showNotificationHandler(
        'success', 
        edit ? 'Trigger updated successfully!' : 'New trigger created successfully!'
      );
      navigate(-1);
    },
    onError: (error: Error) => {
      showNotificationHandler('error', error.message);
    },
  });

  const [trigger, setTrigger] = useState<CreateTriggerType>({
    rule: '',
    trigger_type: '',
    switch: '',
    email: '',
    action: '',
  });

  useEffect(() => {
    if (ruleId) {
      setTrigger((prevTrigger) => ({
        ...prevTrigger,
        rule: ruleId,
      }));
    }
  }, [ruleId]);

  useEffect(() => {
    if (triggerId) {
      setEdit(true);
    }
  }, [triggerId]);

  useEffect(() => {
    if (edit && triggerData) {
      setTrigger({
        rule: triggerData.rule ?? '',
        trigger_type: triggerData.trigger_type ?? '',
        switch: triggerData.switch ?? '',
        email: triggerData.email ?? '',
        action: triggerData.action ?? '',
      });
    }
  }, [edit, triggerData]);

  const {
    data: switches,
    isLoading,
    isError,
    error: switchesError,
  } = useQuery({
    queryFn: listSwitch,
    queryKey: ['listSwitch'],
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setTrigger({ ...trigger, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const triggerData = updateTriggerObject(trigger);
    edit && triggerId
      ? mutate({ triggerData, triggerId })
      : mutate({ triggerData });
  };

  if (isLoading || isPending || isLoadingTrigger) {
    return (
      <div className="flex justify-center items-center min-h-[60vh]">
        <div className="flex flex-col items-center">
          <Spinner />
          <p className="mt-2 text-gray-600">
            {isPending ? 'Processing...' : 'Loading...'}
          </p>
        </div>
      </div>
    );
  }

  if (isError || isErrorTrigger) {
    const errorMessage = isErrorTrigger 
      ? (triggerError as Error)?.message 
      : (switchesError as Error)?.message;
    
    return (
      <div className="flex justify-center items-center p-4">
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{errorMessage || 'Failed to load data'}</span>
          <p className="mt-2 text-sm">Please try again or contact support if the problem persists.</p>
          <div className="mt-4">
            <Link to={ruleId ? `/rule/${ruleId}` : '/rule'} className="text-blue-500 hover:underline">
              ← Back
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto p-4">
      <div className="mb-4">
        <Link to={ruleId ? `/rule/${ruleId}` : '/rule'} className="text-blue-500 hover:underline flex items-center">
          <span className="mr-1">←</span> Back
        </Link>
      </div>
      
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="px-6 py-4 border-b border-gray-200 bg-gray-50">
          <h2 className="text-xl font-semibold text-gray-800">
            {edit ? 'Edit Trigger' : 'Create New Trigger'}
          </h2>
          <p className="text-sm text-gray-600 mt-1">
            {edit 
              ? 'Update the trigger properties below' 
              : 'Configure a new trigger for your rule'}
          </p>
        </div>
        
        <form className="p-6" onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div className="col-span-2">
              <label htmlFor="trigger_type" className="block text-sm font-medium text-gray-700 mb-1">
                Trigger Type
              </label>
              <select
                id="trigger_type"
                name="trigger_type"
                value={trigger.trigger_type}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-200 focus:border-blue-500 outline-none transition"
                required
              >
                <option value="" disabled>
                  Select Trigger Type
                </option>
                <option value="switch">Switch</option>
                <option value="email">Email</option>
              </select>
            </div>

            {trigger.trigger_type === 'switch' && (
              <>
                <div className="col-span-1">
                  <label htmlFor="switch" className="block text-sm font-medium text-gray-700 mb-1">
                    Switch
                  </label>
                  <select
                    id="switch"
                    name="switch"
                    value={trigger.switch}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-200 focus:border-blue-500 outline-none transition"
                    required
                  >
                    <option value="" disabled>
                      Select switch
                    </option>
                    {switches?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {switches?.length === 0 && (
                    <p className="text-yellow-600 text-xs mt-1">
                      No switches available. Please create a switch first.
                    </p>
                  )}
                </div>

                <div className="col-span-1">
                  <label htmlFor="action" className="block text-sm font-medium text-gray-700 mb-1">
                    Action
                  </label>
                  <select
                    id="action"
                    name="action"
                    value={trigger.action}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-200 focus:border-blue-500 outline-none transition"
                    required
                  >
                    <option value="" disabled>
                      Select Action
                    </option>
                    <option value='on'>On</option>
                    <option value='off'>Off</option>
                  </select>
                </div>
              </>
            )}

            {trigger.trigger_type === 'email' && (
              <div className="col-span-2">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={trigger.email}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-200 focus:border-blue-500 outline-none transition"
                  required
                  placeholder='Enter email address'
                />
              </div>
            )}
          </div>

          <div className="mt-6 flex justify-end gap-3">
            <Link 
              to={ruleId ? `/rule/${ruleId}` : '/rule'}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 bg-white hover:bg-gray-50 transition"
            >
              Cancel
            </Link>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition shadow-sm"
              disabled={isPending}
            >
              {isPending ? 'Saving...' : edit ? 'Update Trigger' : 'Create Trigger'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TriggerForm;
