import endpoints from '../endpoints';

export const getUnauthEndpoints = (): string[] => {
  const unauthEndpoints: string[] = [];
  Object.keys(endpoints).forEach((group) => {
    Object.values(endpoints[group as keyof typeof endpoints]).forEach(
      (endpoint) => {
        if (!endpoint.requiresAuth) {
          unauthEndpoints.push(endpoint.path);
        }
      }
    );
  });
  return unauthEndpoints;
};
