import endpoints from '../endpoints';
import api from '../http';
import axios from 'axios';
import {
  CreateDeviceType,
  DeviceCredentialsType,
  DeviceType,
  DeviceTypeType,
  DevicesStatus,
} from '../util/apiTypes';

export const getDeviceList = async (): Promise<DeviceType[]> => {
  const url = endpoints.devices.device.path;
  const { data } = await api.get<{ data: DeviceType[] }>(url);
  return data.data; // Ensure this matches your API response structure
};

export const getDeviceDetail = async (
  deviceId: string
): Promise<DeviceType> => {
  let url = endpoints.devices.getDevice.path;
  url = url.replace(':id', deviceId);
  const { data } = await api.get<{ data: DeviceType }>(url);
  return data.data;
};

export const getDevicesStatus = async (): Promise<DevicesStatus> => {
  const url = endpoints.devices.getDevicesStatus.path;
  const { data } = await api.get<{ data: DevicesStatus }>(url);
  return data.data;
};

export const createDevice = async (
  deviceData: CreateDeviceType
): Promise<DeviceType> => {
  const url = endpoints.devices.device.path;
  const { data } = await api.post<{ data: DeviceType }>(url, deviceData);
  return data.data;
};

export const addDevice = async (
  deiceCredentials: DeviceCredentialsType
): Promise<DeviceType> => {
  const url = endpoints.devices.addDevice.path;
  const { data } = await api.post<{ data: DeviceType }>(url, deiceCredentials);
  return data.data;
};

export const getDeviceTypes = async (): Promise<DeviceTypeType[]> => {
  const url = endpoints.devices.deviceType.path;
  const { data } = await api.get(url);
  return data.data;
};

export const removeDevice = async (deviceId: string): Promise<DeviceType> => {
  let url = endpoints.devices.getDevice.path;
  url = url.replace(':id', deviceId);
  const deviceRemoveData = {
    account: null,
    group: null,
  };
  const { data } = await api.patch<{ data: DeviceType }>(url, deviceRemoveData);
  return data.data;
};

export const getDeviceSecret = async (
  deviceId: string, 
  password: string
): Promise<{ device_secret: string }> => {
  try {
    let url = endpoints.devices.getDeviceSecret.path;
    url = url.replace(':id', deviceId);
    const { data } = await api.post<{ success: boolean, data: { device_secret: string } }>(
      url, 
      { password }
    );
    return data.data;
  } catch (error: unknown) {
    // If the error is related to an invalid password (even with 500 status),
    // provide a clearer error message
    if (axios.isAxiosError(error) && error.response && error.response.status === 500) {
      const errorObj = new Error('Invalid password or server error');
      throw errorObj;
    }
    throw error;
  }
};
