import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { closeSidebar } from '../../features/sidebar/sidebarSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

interface SidebarItemProps {
  icon: IconDefinition;
  label: string;
  isActive: boolean;
  onClick?: () => void;
}

const SidebarItem: React.FC<SidebarItemProps> = ({
  icon,
  label,
  isActive,
  onClick,
}) => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(closeSidebar());
    onClick?.();
  };
  
  return (
    <div
      className={`
        flex items-center px-4 py-3 my-1 mx-2 rounded-lg transition-all duration-200 cursor-pointer
        ${isActive 
      ? 'bg-indigo-50 text-indigo-700 font-medium' 
      : 'text-slate-600 hover:bg-slate-100 hover:text-slate-900'}
      `}
      onClick={handleClick}
    >
      <div className={`
        flex items-center justify-center w-7 h-7
        ${isActive ? 'text-indigo-600' : 'text-slate-500'}
      `}>
        <FontAwesomeIcon icon={icon} className={`w-5 h-5 ${isActive ? 'text-indigo-600' : 'text-slate-500'}`} />
      </div>
      <span className="ml-3 text-sm">{label}</span>
      
      {isActive && (
        <div className="ml-auto w-1.5 h-5 bg-indigo-500 rounded-full"></div>
      )}
    </div>
  );
};

export default SidebarItem;
