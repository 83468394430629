import logo from './logo.png'; // Make sure the path points to your PNG file

const Logo: React.FC = () => {
  return (
    <div className="w-28 sm:w-32 md:w-36 lg:w-40 xl:w-48">
      <img src={logo} alt="Tez Logo" className="w-full h-auto" />
    </div>
  );
};

export default Logo;
