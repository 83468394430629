import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { removeDevice } from '../../api/device/deviceApi';
import { useNavigate } from 'react-router-dom';

interface RemoveModalProps {
  isOpen: boolean;
  onClose: () => void;
  deviceId: string;
  title: string;
}

const DeviceRemoveModal: React.FC<RemoveModalProps> = ({
  isOpen,
  onClose,
  deviceId,
  title,
}) => {
  const [confirmationText, setConfirmationText] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: removeDevice,
    onSuccess: () => {
      onClose();
    },
    onError: (error: AxiosError) => {
      setErrorMessage(error.message);
    },
  });

  const handleDelete = () => {
    mutation.mutate(deviceId);
    navigate(-1);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm transition-opacity">
      <div className="bg-white dark:bg-slate-800 rounded-xl shadow-lg max-w-md w-full mx-auto transform transition-all overflow-hidden">
        <div className="flex justify-between items-center p-5 border-b border-slate-200 dark:border-slate-700">
          <h2 className="text-xl font-semibold text-slate-800 dark:text-white">{title}</h2>
          <button
            onClick={onClose}
            className="text-slate-400 hover:text-slate-600 dark:hover:text-slate-300 transition-colors p-1 rounded-full hover:bg-slate-100 dark:hover:bg-slate-700"
            aria-label="Close"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
        
        <div className="p-5">
          <div className="mb-5 flex items-start p-3 bg-amber-50 border border-amber-100 rounded-lg text-amber-800">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2 flex-shrink-0 text-amber-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
            <p className="text-sm">
              You are about to remove a device. To add the device again, you will
              need the Serial Number and Secret Code. Please type <span className="font-semibold">REMOVE</span> to
              confirm.
            </p>
          </div>
          
          {errorMessage && (
            <div className="mb-4 p-2.5 rounded-lg bg-red-50 border border-red-100 flex items-center text-red-700">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
              <span>{errorMessage}</span>
            </div>
          )}
          
          {mutation.isPending && (
            <div className="mb-4 p-2.5 rounded-lg bg-blue-50 border border-blue-100 flex items-center text-blue-700">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 animate-spin" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
              </svg>
              <span>Processing your request...</span>
            </div>
          )}
          
          <div className="mt-4">
            <label htmlFor="confirmation-text" className="block text-sm font-medium text-slate-700 dark:text-slate-300 mb-1">
              Confirmation
            </label>
            <input
              type="text"
              id="confirmation-text"
              value={confirmationText}
              onChange={(e) => setConfirmationText(e.target.value)}
              placeholder="Type REMOVE to confirm"
              className="block w-full px-3 py-2.5 border border-slate-300 dark:border-slate-600 rounded-lg shadow-sm 
              bg-white dark:bg-slate-700 text-slate-900 dark:text-white
              placeholder-slate-400 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:border-sky-500 
              sm:text-sm transition-colors"
            />
          </div>
          
          <div className="flex justify-end pt-4 border-t border-slate-200 dark:border-slate-700 mt-5">
            <button
              type="button"
              onClick={onClose}
              className="mr-3 px-4 py-2.5 bg-white dark:bg-slate-700 border border-slate-300 dark:border-slate-600
              text-slate-700 dark:text-slate-200 rounded-lg shadow-sm hover:bg-slate-50 dark:hover:bg-slate-600 
              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500 
              transition-colors text-sm font-medium"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleDelete}
              className="px-4 py-2.5 bg-red-500 text-white rounded-lg shadow-sm hover:bg-red-600 
              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 
              transition-colors text-sm font-medium disabled:opacity-70 disabled:cursor-not-allowed"
              disabled={confirmationText !== 'REMOVE' || mutation.isPending}
            >
              Remove Device
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceRemoveModal;
