import {
  DeviceType,
  SensorForDeviceType,
  SwitchType,
  UserType,
  DeviceSecretType,
} from '../../api/util/apiTypes';
import { AxiosError } from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import { getDeviceDetail, getDeviceSecret } from '../../api/device/deviceApi';
import Spinner from '../Spinner';
import SensorCard from '../sensor/SensorCard';
import { getSensorForDevice } from '../../api/sensor/sensorApi';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { RootState } from '../../store/store';
import { getSwitchForDevice } from '../../api/switch/swicthApi';
import SwitchCard from '../switch/SwitchCard';
import { useState } from 'react';
import DeviceRemoveModal from './DeviceRemoveModal';

interface DeviceDetailProps {
  deviceId: string;
}

const DeviceDetail: React.FC<DeviceDetailProps> = ({ deviceId }) => {
  // Device remove modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // Secret key state
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [deviceSecret, setDeviceSecret] = useState<string | null>(null);
  const [showSecret, setShowSecret] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [secretError, setSecretError] = useState<string | null>(null);

  // Toggle device metadata
  const [showDeviceMetadata, setShowDeviceMetadata] = useState(false);
  const toggleDeviceMetadata = () => setShowDeviceMetadata((prev) => !prev);

  // Close password modal and reset password
  const closePasswordModal = () => {
    setIsPasswordModalOpen(false);
    setPassword('');
    setSecretError(null);
  };

  // Copy secret to clipboard
  const copyToClipboard = () => {
    if (deviceSecret) {
      navigator.clipboard.writeText(deviceSecret)
        .then(() => {
          setIsCopied(true);
          setTimeout(() => setIsCopied(false), 2000);
        })
        .catch((err) => {
          console.error('Failed to copy: ', err);
        });
    }
  };

  // Device Secret API
  const secretMutation = useMutation({
    mutationFn: ({ deviceId, password }: { deviceId: string; password: string }) => 
      getDeviceSecret(deviceId, password),
    onSuccess: (data: DeviceSecretType) => {
      setDeviceSecret(data.device_secret);
      setShowSecret(true);
      closePasswordModal();
    },
    onError: (error: Error | AxiosError) => {
      const errorMessage = error.message || 'Failed to retrieve device secret';
      setSecretError(errorMessage);
      setPassword('');
    }
  });

  // Handle password submit
  const handlePasswordSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    secretMutation.mutate({ deviceId, password });
  };

  // Sensor API
  const {
    data: sensorData,
    isLoading: sensorIsLoading,
    error: sensorError,
  } = useQuery<SensorForDeviceType[], AxiosError>({
    queryKey: ['sensorForDevice', deviceId],
    queryFn: () => getSensorForDevice(deviceId),
    refetchInterval: 60000,
  });
  
  // Switch API
  const {
    data: switchData,
    isLoading: switchIsLoading,
    error: switchError,
  } = useQuery<SwitchType[], AxiosError>({
    queryKey: ['switchForDevice', deviceId],
    queryFn: () => getSwitchForDevice(deviceId),
  });

  // Device API
  const { data, isLoading, error } = useQuery<DeviceType, AxiosError>({
    queryKey: ['deviceDetail', deviceId],
    queryFn: () => getDeviceDetail(deviceId),
  });

  const user: UserType = useAppSelector((state: RootState) => state.user);

  if (isLoading || sensorIsLoading || switchIsLoading || !data)
    return <Spinner />;

  if (error instanceof Error)
    return <p className="text-center text-red-500">Error: {error.message}</p>;
  if (sensorError instanceof Error)
    return (
      <p className="text-center text-red-500">Error: {sensorError.message}</p>
    );
  if (switchError instanceof Error)
    return (
      <p className="text-center text-red-500">Error: {switchError.message}</p>
    );

  let readableDate = '';

  if (data) {
    const date = new Date(data.created_at);
    readableDate = date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    });
  }
  
  const isOnline = data?.status === 'Online';

  return (
    <div className="max-w-6xl mx-auto px-4 py-6">
      {/* Hero section with device overview */}
      <div className="relative mb-10 bg-gradient-to-r from-sky-50 to-indigo-50 rounded-2xl p-8 shadow-sm overflow-hidden">
        <div className="absolute inset-0 bg-grid-slate-100 [mask-image:linear-gradient(0deg,#fff,rgba(255,255,255,0.6))] bg-opacity-20"></div>
        
        <div className="relative flex items-start justify-between">
          <div>
            <div className="flex items-center gap-3">
              <h1 className="text-3xl font-bold text-slate-800">
                {data.name || 'Unnamed Device'}
              </h1>
              <div className={`flex items-center px-3 py-1 rounded-full text-sm font-medium ${
                isOnline 
                  ? 'bg-green-100 text-green-800' 
                  : 'bg-red-100 text-red-800'
              }`}>
                <span className={`inline-block h-2 w-2 rounded-full mr-2 ${
                  isOnline ? 'bg-green-500' : 'bg-red-500'
                }`}></span>
                {isOnline ? 'Online' : 'Offline'}
              </div>
            </div>
            <p className="mt-2 text-slate-600 max-w-2xl">
              {data.description || 'No description available'}
            </p>
            
            <div className="mt-3 flex gap-2">
              <button
                onClick={toggleDeviceMetadata}
                className="inline-flex items-center px-3.5 py-1.5 rounded-lg text-sm font-medium transition-colors bg-white border border-slate-200 text-slate-700 hover:bg-slate-50 hover:text-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-500/40"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className={`h-4 w-4 mr-1.5 transition-transform ${showDeviceMetadata ? 'rotate-180' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
                {showDeviceMetadata ? 'Hide Details' : 'View Details'}
              </button>
            </div>
          </div>
          
          <div className="hidden md:block">
            <div className="flex flex-col items-end">
              <div className="text-sm text-slate-500">Device Type</div>
              <div className="text-lg font-medium text-slate-700">{data.type}</div>
              <div className="mt-2 text-sm text-slate-500">Created</div>
              <div className="text-slate-700">{new Date(data.created_at).toLocaleDateString()}</div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Device details panel (collapsible) */}
      {showDeviceMetadata && (
        <div className="mb-8 bg-white rounded-xl shadow-sm border border-slate-200 overflow-hidden transition-all duration-300 ease-in-out">
          <div className="grid md:grid-cols-2 gap-6 p-6">
            <div>
              <h3 className="text-sm font-medium text-slate-500 mb-2">Device Information</h3>
              <div className="space-y-4">
                <div>
                  <div className="text-xs font-medium text-slate-500">ID</div>
                  <div className="text-slate-700 font-mono text-sm mt-0.5">{data.id}</div>
                </div>
                <div>
                  <div className="text-xs font-medium text-slate-500">TYPE</div>
                  <div className="text-slate-700 font-medium mt-0.5">{data.type}</div>
                </div>
                <div>
                  <div className="text-xs font-medium text-slate-500">GROUP</div>
                  <div className="text-slate-700 mt-0.5">{data.group_name || 'None'}</div>
                </div>
                <div>
                  <div className="text-xs font-medium text-slate-500">CREATED</div>
                  <div className="text-slate-700 mt-0.5">{readableDate}</div>
                </div>
              </div>
            </div>
            
            <div>
              <h3 className="text-sm font-medium text-slate-500 mb-2">Secret Key</h3>
              <div className="p-4 bg-slate-50 rounded-lg border border-slate-200">
                {showSecret && deviceSecret ? (
                  <div className="space-y-3">
                    <div className="flex items-center justify-between">
                      <div className="font-mono bg-white text-slate-800 p-3 rounded border border-slate-200 text-sm truncate max-w-md">
                        {deviceSecret}
                      </div>
                      <button 
                        onClick={() => setShowSecret(false)}
                        className="ml-2 p-1.5 rounded-full text-slate-500 hover:text-slate-700 hover:bg-slate-200"
                        aria-label="Hide secret key"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                    <button 
                      onClick={copyToClipboard}
                      className={`w-full py-2 px-3 rounded-lg text-sm font-medium flex justify-center items-center ${
                        isCopied 
                          ? 'bg-green-100 text-green-700' 
                          : 'bg-sky-100 text-sky-700 hover:bg-sky-200'
                      }`}
                    >
                      {isCopied ? (
                        <>
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                          </svg>
                          Copied to clipboard!
                        </>
                      ) : (
                        <>
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                          </svg>
                          Copy to clipboard
                        </>
                      )}
                    </button>
                  </div>
                ) : (
                  <button
                    onClick={() => {
                      if (deviceSecret) {
                        setShowSecret(true);
                      } else {
                        setIsPasswordModalOpen(true);
                      }
                    }}
                    className="w-full py-2.5 px-4 rounded-lg text-sm font-medium bg-white border border-slate-200 text-slate-700 hover:bg-slate-50 hover:text-sky-600 flex items-center justify-center"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
                    </svg>
                    Reveal Secret Key
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      
      {/* Sensors Section */}
      {sensorData && sensorData.length > 0 && (
        <div className="mb-8">
          <div className="flex items-center justify-between mb-5">
            <h2 className="text-xl font-bold text-slate-800 flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-sky-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z" />
              </svg>
              Sensors
              <span className="ml-2 px-2 py-0.5 text-xs font-medium rounded-full bg-sky-100 text-sky-700">
                {sensorData.length}
              </span>
            </h2>
            {user.role === 'tech_support' && (
              <Link
                to={`/tech-support/add-sensor/${data.id}`}
                className="inline-flex items-center px-3 py-1.5 text-sm font-medium rounded-lg border border-sky-200 bg-sky-50 text-sky-700 hover:bg-sky-100"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
                Add Sensor
              </Link>
            )}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
            {sensorData.map((sensor) => (
              <Link key={sensor.id} to={`/sensor/${sensor.id}`} className="group">
                <SensorCard key={sensor.id} {...sensor} />
              </Link>
            ))}
          </div>
        </div>
      )}
      
      {/* Switches Section */}
      {switchData && switchData.length > 0 && (
        <div className="mb-8">
          <div className="flex items-center justify-between mb-5">
            <h2 className="text-xl font-bold text-slate-800 flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-emerald-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
              </svg>
              Switches
              <span className="ml-2 px-2 py-0.5 text-xs font-medium rounded-full bg-emerald-100 text-emerald-700">
                {switchData.length}
              </span>
            </h2>
            {user.role === 'tech_support' && (
              <Link
                to={`/tech-support/add-switch/${data.id}`}
                className="inline-flex items-center px-3 py-1.5 text-sm font-medium rounded-lg border border-emerald-200 bg-emerald-50 text-emerald-700 hover:bg-emerald-100"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
                Add Switch
              </Link>
            )}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
            {switchData.map((item) => (
              <SwitchCard key={item.id} {...item} initialState={item.state} />
            ))}
          </div>
        </div>
      )}
      
      {/* Admin Actions */}
      <div className="mt-10 pt-6 border-t border-slate-200">
        <div className="flex flex-wrap items-center gap-3">
          {user.role === 'tech_support' && (
            <>
              <Link
                to={`/tech-support/add-sensor/${data.id}`}
                className="inline-flex items-center px-4 py-2 rounded-lg text-sm font-medium bg-white border border-slate-200 text-slate-700 hover:bg-slate-50 hover:text-sky-600 transition-colors"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
                Add Sensor
              </Link>
              
              <Link
                to={`/tech-support/add-switch/${data.id}`}
                className="inline-flex items-center px-4 py-2 rounded-lg text-sm font-medium bg-white border border-slate-200 text-slate-700 hover:bg-slate-50 hover:text-sky-600 transition-colors"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
                Add Switch
              </Link>
            </>
          )}
          
          <button
            onClick={openModal}
            className="inline-flex items-center px-4 py-2 rounded-lg text-sm font-medium bg-white border border-red-200 text-red-600 hover:bg-red-50 transition-colors"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>
            Remove Device
          </button>
        </div>
      </div>
      
      {/* Password Modal */}
      {isPasswordModalOpen && (
        <div className="fixed inset-0 bg-black/40 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-white rounded-xl shadow-xl max-w-md w-full mx-4 overflow-hidden">
            <div className="bg-sky-50 px-6 py-4 border-b border-sky-100">
              <h3 className="text-lg font-semibold text-sky-900">Authentication Required</h3>
            </div>
            
            <div className="p-6">
              {secretError && (
                <div className="mb-4 p-3 bg-red-50 border border-red-100 rounded-lg text-sm text-red-600">
                  {secretError}
                </div>
              )}
              
              <p className="mb-4 text-slate-600">
                Please enter your password to reveal the device secret key.
              </p>
              
              <form onSubmit={handlePasswordSubmit}>
                <div className="mb-5">
                  <label className="block text-sm font-medium text-slate-700 mb-1" htmlFor="password">
                    Password
                  </label>
                  <input
                    id="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-3 py-2 rounded-lg border border-slate-300 focus:outline-none focus:ring-2 focus:ring-sky-500/50 focus:border-sky-500"
                    placeholder="Enter your password"
                    required
                    autoComplete="off"
                  />
                </div>
                
                <div className="flex justify-end space-x-3">
                  <button
                    type="button"
                    onClick={closePasswordModal}
                    className="px-4 py-2 rounded-lg text-sm font-medium text-slate-700 hover:bg-slate-100"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 rounded-lg text-sm font-medium bg-sky-600 text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500/50 flex items-center justify-center min-w-[80px]"
                    disabled={secretMutation.isPending}
                  >
                    {secretMutation.isPending ? (
                      <>
                        <svg className="animate-spin h-4 w-4 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Verifying
                      </>
                    ) : (
                      'Submit'
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      
      <DeviceRemoveModal
        isOpen={isModalOpen}
        onClose={closeModal}
        deviceId={data.id}
        title="Remove Device"
      />
    </div>
  );
};

export default DeviceDetail;
