import {
  faTachometerAlt,
  faMicrochip,
  faLayerGroup,
  faUserTie,
  faCode,
  faTemperatureHigh,
  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';
import SidebarItem from '../sidebar/SidebarItem';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { RootState } from '../../store/store';
import { UserType } from '../../api/util/apiTypes';
import logo from '../Logo/logo.png';
import { useAuth } from '../../context/AuthContext';

const Sidebar: React.FC = () => {
  const user: UserType = useAppSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();

  const logoutUser = () => {
    logout();
    navigate('/login');
  };

  const isActive = (path: string) => location.pathname === path;

  return (
    <div className="bg-white min-h-screen w-72 shadow-sm border-r border-slate-200 flex flex-col">
      {/* Logo/App name area */}
      <div className="pt-6 pb-8 px-6">
        <Link to="/" className="inline-block">
          <div className="flex items-center">
            <img src={logo} alt="Tez Logo" className="w-10 h-10 object-contain" />
            <div className="ml-3">
              <h1 className="text-slate-800 font-bold text-xl">TEZ IoT</h1>
              <p className="text-slate-500 text-xs">{user.business.business_name}</p>
            </div>
          </div>
        </Link>
      </div>
      
      {/* Navigation section */}
      <div className="flex-1">
        <div className="px-3 mb-6">
          <div className="px-4 mb-2">
            <span className="text-xs font-medium uppercase tracking-wider text-slate-500">Navigation</span>
          </div>
        
          <Link to="/">
            <SidebarItem
              icon={faTachometerAlt}
              label="Dashboard"
              isActive={isActive('/')}
            />
          </Link>
          <Link to="/group">
            <SidebarItem
              icon={faLayerGroup}
              label="Groups"
              isActive={isActive('/group')}
            />
          </Link>
          <Link to="/device">
            <SidebarItem
              icon={faMicrochip}
              label="Devices"
              isActive={isActive('/device')}
            />
          </Link>
          <Link to="/sensor">
            <SidebarItem
              icon={faTemperatureHigh}
              label="Sensors"
              isActive={isActive('/sensor')}
            />
          </Link>
          <Link to="/rule">
            <SidebarItem
              icon={faCode}
              label="Rules"
              isActive={isActive('/rule')}
            />
          </Link>
          
          {user.role === 'tech_support' && (
            <Link to="/tech-support">
              <SidebarItem
                icon={faUserTie}
                label="Tech Support"
                isActive={isActive('/tech-support')}
              />
            </Link>
          )}
        </div>
      </div>
      
      {/* User section */}
      <div className="px-3 pb-6 mt-auto">
        <div className="px-4 mb-2">
          <span className="text-xs font-medium uppercase tracking-wider text-slate-500">User</span>
        </div>
        
        <div className="bg-slate-50 rounded-xl p-4 mb-3 mx-2">
          <div className="flex items-center">
            <div className="w-10 h-10 rounded-full bg-slate-200 flex items-center justify-center text-slate-600 font-medium text-sm">
              {user.first_name ? user.first_name.charAt(0).toUpperCase() : user.email.charAt(0).toUpperCase()}
            </div>
            <div className="ml-3">
              <div className="text-sm font-medium text-slate-800">{user.first_name || user.email}</div>
              <div className="text-xs text-slate-500 capitalize">{user.role || 'Member'}</div>
            </div>
          </div>
        </div>
        
        <SidebarItem
          icon={faSignOutAlt}
          label="Logout"
          onClick={logoutUser}
          isActive={false}
        />
      </div>
    </div>
  );
};

export default Sidebar;
