import { useQuery } from '@tanstack/react-query';
import { UserType } from '../api/util/apiTypes';
import { closeSidebar } from '../features/sidebar/sidebarSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { Outlet } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { setUser } from '../features/user/userSlice';
import { getUser } from '../api/user/userApi';
import Spinner from '../components/Spinner';
import Notification from '../components/Notification';
import Header from '../components/Header';
import Sidebar from '../components/sidebar/Sidebar';

const RootLayout: React.FC = () => {
  const dispatch = useAppDispatch();
  const { data, isLoading, error } = useQuery<UserType, AxiosError>({
    queryKey: ['user'],
    queryFn: getUser,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data) {
      dispatch(setUser(data));
    }
  }, [data, dispatch]);

  const isSidebarOpen = useAppSelector(
    (state: RootState) => state.sidebar.isOpen
  );

  if (isLoading) return <Spinner />;
  if (error instanceof Error)
    return <p className="text-center text-red-500">Error: {error.message}</p>;

  const handleOverlayClick = () => {
    dispatch(closeSidebar());
  };

  return (
    <>
      <div className="flex bg-gray-100 overflow-hidden">
        {isSidebarOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-30 md:hidden"
            onClick={handleOverlayClick}
          ></div>
        )}
        <div
          className={`${
            isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
          } md:translate-x-0 fixed md:static top-0 left-0 h-full transition-transform duration-300 z-30`}
        >
          <Sidebar />
        </div>
        <div className="flex flex-col flex-1 min-h-screen overflow-auto">
          <Header />
          <div className="flex-1 overflow-y-auto">
            <Outlet />
          </div>
        </div>
      </div>
      <Notification />
    </>
  );
};

export default RootLayout;
