import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAirFreshener,
  faBoltLightning,
  faDoorOpen,
  faTemperatureHigh,
  faTint,
  faWalking,
  faWater,
} from '@fortawesome/free-solid-svg-icons';
import { SensorForDeviceType } from '../../api/util/apiTypes';

const SensorCard: React.FC<SensorForDeviceType> = ({
  name,
  type,
  value,
  unit,
  timestamp,
  status,
}) => {
  let readableDate = '';
  if (timestamp) {
    const date = new Date(timestamp);
    readableDate = date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    });
  }

  const getSensorIcon = (type: string) => {
    switch (type) {
    case 'TEMPERATURE':
      return faTemperatureHigh;
    case 'HUMIDITY':
      return faTint;
    case 'MOISTURE':
      return faWater;
    case 'LIGHT':
      return faBoltLightning;
    case 'DOOR':
      return faDoorOpen;
    case 'MOTION':
      return faWalking;
    case 'CO2':
      return faAirFreshener;
    default:
      return faTemperatureHigh;
    }
  };

  const getSensorUnit = (unit: string) => {
    switch (unit) {
    case 'celsius':
      return '°C';
    case 'fahrenheit':
      return '°F';
    case 'percentage':
      return '%';
    case 'lumens':
      return 'lm';
    default:
      return unit;
    }
  };
  
  // Helper function to get a color based on sensor type
  const getSensorTypeColor = (type: string) => {
    switch (type) {
    case 'TEMPERATURE':
      return 'from-orange-50 to-amber-50';
    case 'HUMIDITY':
      return 'from-blue-50 to-cyan-50';
    case 'MOISTURE':
      return 'from-emerald-50 to-teal-50';
    case 'LIGHT':
      return 'from-yellow-50 to-amber-50';
    case 'DOOR':
      return 'from-indigo-50 to-violet-50';
    case 'MOTION':
      return 'from-fuchsia-50 to-pink-50';
    case 'CO2':
      return 'from-lime-50 to-emerald-50';
    default:
      return 'from-sky-50 to-indigo-50';
    }
  };
  
  // Helper function to get value color based on sensor type
  const getValueColor = (type: string) => {
    switch (type) {
    case 'TEMPERATURE':
      return 'text-orange-500';
    case 'HUMIDITY':
      return 'text-blue-500';
    case 'MOISTURE':
      return 'text-emerald-500';
    case 'LIGHT':
      return 'text-yellow-500';
    case 'DOOR':
      return 'text-indigo-500';
    case 'MOTION':
      return 'text-fuchsia-500';
    case 'CO2':
      return 'text-lime-600';
    default:
      return 'text-sky-500';
    }
  };
  
  // Helper function to get icon color based on sensor type
  const getIconColor = (type: string) => {
    switch (type) {
    case 'TEMPERATURE':
      return 'text-orange-400';
    case 'HUMIDITY':
      return 'text-blue-400';
    case 'MOISTURE':
      return 'text-emerald-400';
    case 'LIGHT':
      return 'text-yellow-400';
    case 'DOOR':
      return 'text-indigo-400';
    case 'MOTION':
      return 'text-fuchsia-400';
    case 'CO2':
      return 'text-lime-500';
    default:
      return 'text-sky-400';
    }
  };
  
  const isOnline = status === 'Online';
  
  return (
    <div className={`relative bg-gradient-to-r ${getSensorTypeColor(type)} rounded-xl shadow-sm overflow-hidden transition-all duration-300 hover:shadow-md group`}>
      <div className="absolute inset-0 bg-grid-slate-100 [mask-image:linear-gradient(0deg,#fff,rgba(255,255,255,0.6))] bg-opacity-20"></div>
      
      <div className="relative p-6">
        <div className="flex items-center justify-between mb-3">
          <div className="flex items-center">
            <div className={`p-2.5 rounded-lg ${getIconColor(type)} bg-white/80 shadow-sm mr-3`}>
              <FontAwesomeIcon
                icon={getSensorIcon(type)}
                className="text-lg"
              />
            </div>
            <h3 className="font-semibold text-slate-800 group-hover:text-slate-900 transition-colors">
              {name}
            </h3>
          </div>
          
          <div className={`flex items-center px-2.5 py-1 rounded-full text-xs font-medium ${
            isOnline 
              ? 'bg-green-100 text-green-700' 
              : 'bg-red-100 text-red-700'
          }`}>
            <span className={`inline-block h-1.5 w-1.5 rounded-full mr-1.5 ${
              isOnline ? 'bg-green-500' : 'bg-red-500'
            }`}></span>
            {isOnline ? 'Online' : 'Offline'}
          </div>
        </div>
        
        <div className="flex items-baseline mb-4">
          <span className={`text-4xl font-bold ${getValueColor(type)}`}>
            {type === 'CO2' && typeof value === 'number' 
              ? Math.round(value) 
              : typeof value === 'number' ? value.toFixed(1) : value}
          </span>
          <span className="text-lg text-slate-500 ml-1.5">
            {unit ? getSensorUnit(unit) : ''}
          </span>
        </div>
        
        {readableDate && (
          <div className="flex items-center text-xs text-slate-500">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5 mr-1.5 text-slate-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            {readableDate}
          </div>
        )}
      </div>
      
      <div className="absolute top-3 right-3 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
        <div className="p-1 rounded-full bg-white/80 shadow-sm text-slate-400 hover:text-sky-500 cursor-pointer transition-colors">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default SensorCard;
