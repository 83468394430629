import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { showNotification } from '../../features/notificationSlice';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Spinner from '../Spinner';
import { CreateRuleType, UserType } from '../../api/util/apiTypes';
import { listSensor } from '../../api/sensor/sensorApi';
import { createRule, getRule, updateRule } from '../../api/rule/ruleApi';
import { RootState } from '../../store/store';

const CreateRuleForm: React.FC = () => {
  const { ruleId } = useParams<{ ruleId: string }>();
  const [edit, setEdit] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user: UserType = useAppSelector((state: RootState) => state.user);

  const showNotificationHandler = (type: 'success' | 'error', message: string) => {
    dispatch(
      showNotification({ type, message, duration: 3000 })
    );
  };

  const {
    data: ruleData,
    isLoading: isLoadingRule,
    isError: isErrorRule,
    error: ruleError,
  } = useQuery({
    queryFn: () => getRule(ruleId ? ruleId : ''),
    queryKey: ['Rule', ruleId],
    enabled: !!ruleId,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (variables: { rule: CreateRuleType; ruleId?: string }) => {
      if (edit && variables.ruleId) {
        return updateRule(variables.rule, variables.ruleId);
      } else {
        return createRule(variables.rule);
      }
    },
    onSuccess: () => {
      showNotificationHandler(
        'success', 
        edit ? 'Rule updated successfully!' : 'New rule created successfully!'
      );
      navigate('/rule');
    },
    onError: (error: Error) => {
      showNotificationHandler('error', error.message);
    },
  });

  const [rule, setRule] = useState<CreateRuleType>({
    account: '',
    name: '',
    sensor: '',
    switch: '',
    condition: '',
    threshold_value: 0,
    description: '',
  });

  useEffect(() => {
    if (user?.business?.id) {
      setRule((prevRule) => ({
        ...prevRule,
        account: user.business.id,
      }));
    }
  }, [user]);

  useEffect(() => {
    if (ruleId) {
      setEdit(true);
    }
  }, [ruleId]);

  useEffect(() => {
    if (edit && ruleData) {
      setRule({
        account: ruleData.account ?? user?.business?.id ?? '',
        name: ruleData.name ?? '',
        sensor: ruleData.sensor ?? '',
        switch: ruleData.switch ?? '',
        condition: ruleData.condition ?? '',
        threshold_value: ruleData.threshold_value ?? 0,
        description: ruleData.description ?? '',
      });
    }
  }, [edit, ruleData, user]);

  const {
    data: sensors,
    isLoading,
    isError,
    error: sensorsError,
  } = useQuery({
    queryFn: listSensor,
    queryKey: ['listSensors'],
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    
    if (name === 'threshold_value') {
      setRule({ ...rule, [name]: Number(value) });
    } else {
      setRule({ ...rule, [name]: value });
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    edit ? mutate({ rule, ruleId }) : mutate({ rule });
  };

  if (isLoading || isPending || isLoadingRule) {
    return (
      <div className="flex justify-center items-center min-h-[60vh]">
        <div className="flex flex-col items-center">
          <Spinner />
          <p className="mt-2 text-gray-600">
            {isPending ? 'Processing...' : 'Loading...'}
          </p>
        </div>
      </div>
    );
  }

  if (isError || isErrorRule) {
    const errorMessage = isErrorRule 
      ? (ruleError as Error)?.message 
      : (sensorsError as Error)?.message;
    
    return (
      <div className="flex justify-center items-center p-4">
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{errorMessage || 'Failed to load data'}</span>
          <p className="mt-2 text-sm">Please try again or contact support if the problem persists.</p>
          <div className="mt-4">
            <Link to="/rule" className="text-blue-500 hover:underline">
              ← Back to Rules
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto p-4">
      <div className="mb-4">
        <Link to="/rule" className="text-blue-500 hover:underline flex items-center">
          <span className="mr-1">←</span> Back to Rules
        </Link>
      </div>
      
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="px-6 py-4 border-b border-gray-200 bg-gray-50">
          <h2 className="text-xl font-semibold text-gray-800">
            {edit ? 'Edit Rule' : 'Create New Rule'}
          </h2>
          <p className="text-sm text-gray-600 mt-1">
            {edit 
              ? 'Update the rule properties below' 
              : 'Configure a new rule to monitor sensor data'}
          </p>
        </div>
        
        <form className="p-6" onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div className="col-span-2">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                Rule Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={rule.name}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-200 focus:border-blue-500 outline-none transition"
                required
                placeholder="Enter rule name"
              />
            </div>

            <div className="col-span-2">
              <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
                Description
              </label>
              <textarea
                id="description"
                name="description"
                value={rule.description}
                onChange={handleChange}
                rows={3}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-200 focus:border-blue-500 outline-none transition"
                required
                placeholder="Enter rule description"
              />
            </div>

            <div>
              <label htmlFor="sensor" className="block text-sm font-medium text-gray-700 mb-1">
                Sensor
              </label>
              <select
                id="sensor"
                name="sensor"
                value={rule.sensor}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-200 focus:border-blue-500 outline-none transition"
                required
              >
                <option value="" disabled>
                  Select sensor
                </option>
                {sensors?.map((sensor) => (
                  <option key={sensor.id} value={sensor.id}>
                    {sensor.name}
                  </option>
                ))}
              </select>
              {sensors?.length === 0 && (
                <p className="text-yellow-600 text-xs mt-1">
                  No sensors available. Please create a sensor first.
                </p>
              )}
            </div>

            <div>
              <label htmlFor="condition" className="block text-sm font-medium text-gray-700 mb-1">
                Condition
              </label>
              <select
                id="condition"
                name="condition"
                value={rule.condition}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-200 focus:border-blue-500 outline-none transition"
                required
              >
                <option value="" disabled>
                  Select condition
                </option>
                <option value="gt">Greater than</option>
                <option value="lt">Less than</option>
              </select>
            </div>

            <div>
              <label htmlFor="threshold" className="block text-sm font-medium text-gray-700 mb-1">
                Threshold Value
              </label>
              <input
                type="number"
                id="threshold"
                name="threshold_value"
                value={rule.threshold_value}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-200 focus:border-blue-500 outline-none transition"
                required
                placeholder="Enter threshold value"
              />
            </div>
          </div>

          <div className="mt-6 flex justify-end gap-3">
            <Link 
              to="/rule"
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 bg-white hover:bg-gray-50 transition"
            >
              Cancel
            </Link>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition shadow-sm"
              disabled={isPending}
            >
              {isPending ? 'Saving...' : edit ? 'Update Rule' : 'Create Rule'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateRuleForm;
