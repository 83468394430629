import { useQuery } from '@tanstack/react-query';
import { getRule } from '../../api/rule/ruleApi';
import { useParams, Link } from 'react-router-dom';
import Spinner from '../Spinner';
import { listSensor } from '../../api/sensor/sensorApi';
import GenericTable from '../generic/GenericTable';
import { deleteTrigger, listTriggerRule } from '../../api/rule/triggerApi';
import { listSwitch } from '../../api/switch/swicthApi';
import { format } from 'date-fns';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faInfoCircle, 
  faBolt, 
  faChartLine 
} from '@fortawesome/free-solid-svg-icons';

const RuleDetail: React.FC = () => {
  const { ruleId } = useParams<{ ruleId: string }>();
  const [showRuleDetails, setShowRuleDetails] = useState(false);
  
  const toggleRuleDetails = () => setShowRuleDetails(prev => !prev);

  const {
    data: sensors,
    isLoading: isLoadingSensors,
    isError: isErrorSensors,
  } = useQuery({
    queryFn: listSensor,
    queryKey: ['listSensors'],
  });

  const {
    data: switches,
    isLoading: isLoadingSwitches,
    isError: isErrorSwitches,
  } = useQuery({
    queryFn: listSwitch,
    queryKey: ['listSwitch'],
  });

  const { 
    data, 
    isLoading, 
    isError,
    error
  } = useQuery({
    queryFn: () => getRule(ruleId ? ruleId : ''),
    queryKey: ['detailData', ruleId],
    enabled: !!ruleId,
  });

  const {
    data: triggersData,
    isLoading: isLoadingTrigger,
    isError: isErrorTrigger,
    error: triggersError,
  } = useQuery({
    queryFn: () => listTriggerRule(ruleId ? ruleId : ''),
    queryKey: ['tableData', ruleId],
    enabled: !!ruleId,
  });

  // Check if rule is enabled (assuming 'active' could be the field name if 'enabled' doesn't exist)
  const isRuleEnabled = () => {
    // Use optional chaining with appropriate type for rule data
    return Boolean((data as {enabled?: boolean; active?: boolean})?.enabled) || 
           Boolean((data as {enabled?: boolean; active?: boolean})?.active) || 
           true;
  };

  const getSensorName = (sensorId: string | undefined) => {
    if (!sensorId) return 'Unknown Sensor';
    const sensor = sensors?.find((sensor) => sensor.id === sensorId);
    return sensor ? sensor.name : 'Unknown Sensor';
  };

  const getCondition = (condition: string | undefined) => {
    switch (condition) {
    case 'gt': return 'Greater than';
    case 'lt': return 'Less than';
    default: return '-';
    }
  };
  
  const getSwitchName = (switchId: string | undefined) => {
    if (!switchId) return '-';
    const _switch = switches?.find((_switch) => _switch.id === switchId);
    return _switch ? _switch.name : 'Unknown Switch';
  };

  const getReadableDate = (rawDate: string | undefined) => {
    if (!rawDate) return '-';
    try {
      const date = new Date(rawDate);
      return format(date, 'PPpp');
    } catch (e) {
      return 'Invalid date';
    }
  };

  const columns = [
    { header: 'Trigger Type', key: 'trigger_type' },
    { header: 'Switch', key: 'switch' },
    { header: 'Email', key: 'email' },
    { header: 'Action', key: 'action' },
    { header: 'Last email sent', key: 'last_email_sent' },
    { header: 'Actions', key: 'actions' },
  ];

  const tableData = triggersData
    ?.filter((trigger) => trigger !== null && trigger !== undefined)
    .map((trigger) => ({
      ...trigger,
      switch: trigger.switch ? getSwitchName(trigger.switch) : '-',
      last_email_sent: trigger.last_email_sent
        ? getReadableDate(trigger.last_email_sent)
        : '-',
      email: trigger.email ?? '-',
      action: trigger.action ?? '-',
    }));

  const tableConfig = {
    addButtonTitle: 'Add Trigger',
    addNewRoute: `/trigger/add-new/${ruleId}`,
    deleteApi: deleteTrigger,
    baseRoute: '/trigger',
    editable: true,
    deletable: true,
  };

  if (isLoading || isLoadingSensors || isLoadingSwitches || isLoadingTrigger) {
    return (
      <div className="p-4">
        <div className="flex flex-col items-center justify-center mt-8">
          <Spinner />
          <p className="text-gray-500 mt-2">Loading rule details...</p>
        </div>
      </div>
    );
  }

  if (isError || isErrorSensors || isErrorSwitches || isErrorTrigger) {
    const errorMessage = (error as Error)?.message || 
                         (triggersError as Error)?.message || 
                         'Failed to load rule details';
    
    return (
      <div className="flex justify-center items-center p-4">
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{errorMessage}</span>
          <p className="mt-2 text-sm">Please try again or contact support if the problem persists.</p>
        </div>
      </div>
    );
  }

  if (!data) {
    return (
      <div className="flex justify-center items-center p-4">
        <div className="bg-yellow-50 border border-yellow-200 text-yellow-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Not Found: </strong>
          <span className="block sm:inline">The requested rule could not be found.</span>
        </div>
      </div>
    );
  }

  const ruleEnabled = isRuleEnabled();

  return (
    <div className="max-w-6xl mx-auto px-4 py-6">
      {/* Hero section with rule overview */}
      <div className="relative mb-10 bg-gradient-to-r from-amber-50 to-orange-50 rounded-2xl p-8 shadow-sm overflow-hidden">
        <div className="absolute inset-0 bg-grid-slate-100 [mask-image:linear-gradient(0deg,#fff,rgba(255,255,255,0.6))] bg-opacity-20"></div>
        
        <div className="relative flex items-start justify-between">
          <div>
            <div className="flex items-center gap-3">
              <h1 className="text-3xl font-bold text-slate-800">
                {data.name || 'Unnamed Rule'}
              </h1>
              <div className={`flex items-center px-3 py-1 rounded-full text-sm font-medium ${
                ruleEnabled 
                  ? 'bg-green-100 text-green-800' 
                  : 'bg-gray-100 text-gray-800'
              }`}>
                <span className={`inline-block h-2 w-2 rounded-full mr-2 ${
                  ruleEnabled ? 'bg-green-500' : 'bg-gray-500'
                }`}></span>
                {ruleEnabled ? 'Active' : 'Disabled'}
              </div>
            </div>
            <p className="mt-2 text-slate-600 max-w-2xl">
              {data.description || 'No description available'}
            </p>
            
            <div className="mt-3 flex gap-2">
              <button
                onClick={toggleRuleDetails}
                className="inline-flex items-center px-3.5 py-1.5 rounded-lg text-sm font-medium transition-colors bg-white border border-slate-200 text-slate-700 hover:bg-slate-50 hover:text-amber-600 focus:outline-none focus:ring-2 focus:ring-amber-500/40"
              >
                <FontAwesomeIcon 
                  icon={faInfoCircle} 
                  className={`mr-1.5 transition-transform ${showRuleDetails ? 'rotate-180' : ''}`} 
                />
                {showRuleDetails ? 'Hide Details' : 'View Details'}
              </button>
              
              <Link 
                to="/rule" 
                className="inline-flex items-center px-3.5 py-1.5 rounded-lg text-sm font-medium transition-colors bg-white border border-slate-200 text-slate-700 hover:bg-slate-50 hover:text-amber-600 focus:outline-none focus:ring-2 focus:ring-amber-500/40"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                </svg>
                Back to Rules
              </Link>
            </div>
          </div>
          
          <div className="hidden md:block">
            <div className="flex flex-col items-end">
              <div className="text-sm text-slate-500">Condition</div>
              <div className="text-lg font-medium text-amber-600">
                {getCondition(data.condition)}
              </div>
              <div className="text-sm text-slate-500 mt-2">Threshold</div>
              <div className="text-lg font-medium text-amber-600">
                {data.threshold_value}
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Rule details panel (collapsible) */}
      {showRuleDetails && (
        <div className="mb-8 bg-white rounded-xl shadow-sm border border-slate-200 overflow-hidden transition-all duration-300 ease-in-out">
          <div className="grid md:grid-cols-2 gap-6 p-6">
            <div>
              <h3 className="text-sm font-medium text-slate-500 mb-2">Rule Information</h3>
              <div className="space-y-4">
                <div>
                  <div className="text-xs font-medium text-slate-500">ID</div>
                  <div className="text-slate-700 font-mono text-sm mt-0.5">{data.id}</div>
                </div>
                <div>
                  <div className="text-xs font-medium text-slate-500">SENSOR</div>
                  <div className="text-slate-700 font-medium mt-0.5">{getSensorName(data.sensor)}</div>
                </div>
                <div>
                  <div className="text-xs font-medium text-slate-500">TRIGGERS</div>
                  <div className="text-slate-700 mt-0.5">{data.trigger_count || '0'}</div>
                </div>
                <div>
                  <div className="text-xs font-medium text-slate-500">CREATED</div>
                  <div className="text-slate-700 mt-0.5">{getReadableDate((data as {created_at?: string})?.created_at)}</div>
                </div>
              </div>
            </div>
            
            <div>
              <h3 className="text-sm font-medium text-slate-500 mb-2">Condition Details</h3>
              <div className="p-4 bg-slate-50 rounded-lg border border-slate-200">
                <div className="space-y-3">
                  <div className="flex items-center">
                    <FontAwesomeIcon icon={faBolt} className="text-lg mr-3 text-amber-500" />
                    <div>
                      <div className="text-xs font-medium text-slate-500">CONDITION</div>
                      <div className="text-slate-700">{getCondition(data.condition)}</div>
                    </div>
                  </div>
                  
                  <div className="flex items-center">
                    <FontAwesomeIcon icon={faChartLine} className="text-lg mr-3 text-amber-500" />
                    <div>
                      <div className="text-xs font-medium text-slate-500">THRESHOLD VALUE</div>
                      <div className="text-slate-700">{data.threshold_value}</div>
                    </div>
                  </div>
                  
                  <div className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-3 text-slate-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                    </svg>
                    <div>
                      <div className="text-xs font-medium text-slate-500">STATUS</div>
                      <div className="flex items-center">
                        <span className="text-slate-700">{ruleEnabled ? 'Active' : 'Disabled'}</span>
                        <span
                          className={`ml-2 rounded-full h-2 w-2 ${ruleEnabled ? 'bg-green-500' : 'bg-gray-500'}`}
                          title={ruleEnabled ? 'Active' : 'Disabled'}
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      
      {/* Triggers Section */}
      <div className="mt-8 bg-white rounded-lg shadow-sm">
        <div className="flex justify-between items-center px-6 py-4 border-b border-gray-200">
          <h2 className="text-xl font-semibold text-gray-800 flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-amber-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
            </svg>
            Triggers
            {tableData && tableData.length > 0 && (
              <span className="ml-2 px-2 py-0.5 text-xs font-medium rounded-full bg-amber-100 text-amber-700">
                {tableData.length}
              </span>
            )}
          </h2>
          <Link 
            to={`/trigger/add-new/${ruleId}`}
            className="px-4 py-2 bg-amber-500 text-white rounded-md hover:bg-amber-600 transition shadow-sm flex items-center"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
            </svg>
            Add Trigger
          </Link>
        </div>
        
        {tableData && tableData.length > 0 ? (
          <div className="p-4">
            <div className="overflow-hidden bg-white rounded-lg border border-slate-200">
              <GenericTable data={tableData} columns={columns} config={tableConfig} />
            </div>
          </div>
        ) : (
          <div className="text-center p-8">
            <svg 
              className="mx-auto h-12 w-12 text-slate-400" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor" 
              aria-hidden="true"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" 
              />
            </svg>
            <p className="mt-4 text-lg font-medium text-slate-700">No triggers configured</p>
            <p className="mt-2 text-slate-500 max-w-md mx-auto">
              Add a trigger to automate actions when this rule is activated.
            </p>
            <div className="mt-6">
              <Link 
                to={`/trigger/add-new/${ruleId}`}
                className="inline-flex items-center px-4 py-2 bg-amber-500 text-white rounded-md hover:bg-amber-600 transition shadow-sm"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
                Add Your First Trigger
              </Link>
            </div>
          </div>
        )}
      </div>
      
      {/* Additional Actions Section */}
      <div className="mt-10 pt-6 border-t border-slate-200">
        <div className="flex flex-wrap items-center gap-3">
          <Link
            to={`/rule/edit/${ruleId}`}
            className="inline-flex items-center px-4 py-2 rounded-lg text-sm font-medium bg-white border border-slate-200 text-slate-700 hover:bg-slate-50 hover:text-amber-600 transition-colors"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
            </svg>
            Edit Rule
          </Link>
          
          <Link
            to={`/trigger/add-new/${ruleId}`}
            className="inline-flex items-center px-4 py-2 rounded-lg text-sm font-medium bg-white border border-slate-200 text-slate-700 hover:bg-slate-50 hover:text-amber-600 transition-colors"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
            </svg>
            Add Trigger
          </Link>
          
          <button
            className="inline-flex items-center px-4 py-2 rounded-lg text-sm font-medium bg-white border border-red-200 text-red-600 hover:bg-red-50 transition-colors"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>
            Delete Rule
          </button>
        </div>
      </div>
    </div>
  );
};

export default RuleDetail;
