import './App.css';
import HomePage from './pages/HomePage';
import Login from './pages/Login';
import {
  createBrowserRouter,
  RouterProvider,
  RouteObject,
} from 'react-router-dom';
import RootLayout from './pages/RootLayout';
import SignUp from './pages/SignUp';
import DevicePage from './pages/DevicePage';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import User from './pages/User';
import Group from './components/group/Group';
import TechSupport from './components/techSupport/TechSupport';
import CreateDeviceForm from './components/device/CreateDeviceForm';
import CreateSensorForm from './components/sensor/CreatSensorForm';
import CreateSwitchForm from './components/switch/CreateSwitchForm';
import VerifyEmail from './components/user/verifyEmail';
import NotFoundPage from './pages/NotFound';
import ErrorBoundary from './components/error/ErrorBoundary';
import CreateRuleForm from './components/rule/CreateRule';
import Rule from './components/rule/Rule';
import RuleDetail from './components/rule/RuleDetail';
import TriggerForm from './components/trigger/CreateTrigger';
import SensorPage from './pages/SensorPage';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';

const routes: RouteObject[] = [
  {
    path: '/',
    element: <RootLayout />,
    children: [
      { path: '/', element: <HomePage /> },
      { path: '/device', element: <DevicePage /> },
      { path: '/device/:deviceId', element: <DevicePage /> },
      { path: '/group', element: <Group /> },
      { path: '/group/:groupId', element: <Group /> },
      { path: '/sensor', element: <SensorPage /> },
      { path: '/sensor/:sensorId', element: <SensorPage /> },
      { path: '/rule', element: <Rule /> },
      { path: '/rule/add-new', element: <CreateRuleForm /> },
      { path: '/rule/:ruleId', element: <RuleDetail /> },
      { path: '/rule/edit/:ruleId', element: <CreateRuleForm /> },
      { path: '/trigger/add-new/:ruleId', element: <TriggerForm /> },
      { path: '/trigger/edit/:triggerId', element: <TriggerForm /> },
      {
        path: '/tech-support',
        element: <TechSupport />,
        children: [
          { path: 'add-device', element: <CreateDeviceForm /> },
          { path: 'add-sensor', element: <CreateSensorForm /> },
          { path: 'add-sensor/:deviceId', element: <CreateSensorForm /> },
          { path: 'add-switch/:deviceId', element: <CreateSwitchForm /> },
        ],
      },
    ],
  },
  { path: '/login', element: <Login /> },
  { path: '/signup', element: <SignUp /> },
  { path: '/user', element: <User /> },
  { path: 'verify-email', element: <VerifyEmail /> },
  { path: '/forgot-password', element: <ForgotPassword /> },
  { path: '/reset-password', element: <ResetPassword /> },
  { path: '*', element: <NotFoundPage /> },
];

const queryClient = new QueryClient();
const router = createBrowserRouter(routes);

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <RouterProvider router={router} />
      </ErrorBoundary>
    </QueryClientProvider>
  );
};

export default App;
