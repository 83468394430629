import { useQuery } from '@tanstack/react-query';
import { listSensor } from '../../api/sensor/sensorApi';
import Spinner from '../Spinner';
import SensorCard from './SensorCard';
import { Link } from 'react-router-dom';

const SensorList: React.FC = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['sensorList'],
    queryFn: listSensor,
  });

  if (isLoading) return <Spinner />;
  if (error instanceof Error)
    return <p className="text-center text-red-500">Error: {error.message}</p>;

  if (!data || data.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center py-12 bg-white rounded-xl shadow-sm border border-slate-200 text-center px-4">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-slate-300 mb-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
          <rect x="2" y="6" width="20" height="12" rx="2" strokeWidth="1.5" />
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M7 10h10" />
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M7 14h7" />
        </svg>
        <h3 className="text-xl font-semibold text-slate-700 mb-2">No sensors available</h3>
        <p className="text-slate-500 max-w-md mb-6">You need to add a device with sensors to a group first to start collecting data</p>
        <Link
          to="/group"
          className="inline-flex items-center px-4 py-2 rounded-lg text-sm font-medium transition-colors bg-indigo-500 text-white hover:bg-indigo-600"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
          Go to Groups
        </Link>
      </div>
    );
  }
  
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {data.map((sensor) => (
          <Link key={sensor.id} to={`/sensor/${sensor.id}`}>
            <SensorCard key={sensor.id} {...sensor} />
          </Link>
        ))}
      </div>
    </>
  );
};

export default SensorList;
