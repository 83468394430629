import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { getDevicesStatus } from '../../api/device/deviceApi';
import { DevicesStatus } from '../../api/util/apiTypes';
import Spinner from '../Spinner';

const DeviceStatus: React.FC = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['devicesStatus'],
    queryFn: getDevicesStatus,
  });

  if (isLoading) return <Spinner />;
  if (error instanceof Error)
    return <p className="text-center text-red-500">Error: {error.message}</p>;

  const { total, online, online_percent, offline, offline_percent } =
    data as DevicesStatus;

  return (
    <div className="bg-white shadow-sm border border-slate-200 p-6 rounded-xl">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-bold text-slate-800">Device Status</h2>
        <div className="bg-indigo-50 text-indigo-700 font-medium px-3 py-1 rounded-full text-xs">
          Real-time
        </div>
      </div>

      <div className="flex flex-col sm:flex-row items-center justify-between mb-6">
        <div className="text-center sm:text-left mb-4 sm:mb-0">
          <div className="text-sm text-slate-500 mb-1">Total Devices</div>
          <div className="text-3xl font-bold text-slate-800">{total}</div>
        </div>
        
        <div className="flex space-x-6">
          <div className="text-center">
            <div className="text-sm text-slate-500 mb-1">Online</div>
            <div className="text-2xl font-semibold text-emerald-600">{online}</div>
          </div>
          
          <div className="text-center">
            <div className="text-sm text-slate-500 mb-1">Offline</div>
            <div className="text-2xl font-semibold text-rose-600">{offline}</div>
          </div>
        </div>
      </div>
      
      <div className="space-y-5">
        <div>
          <div className="flex justify-between mb-1.5">
            <span className="text-sm font-medium text-slate-700">Online Devices</span>
            <div className="flex items-center">
              <span className="text-sm font-semibold text-emerald-600">
                {online_percent}%
              </span>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1 text-emerald-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
              </svg>
            </div>
          </div>
          <div className="w-full h-2 bg-slate-100 rounded-full overflow-hidden">
            <div
              className="h-full bg-emerald-500 rounded-full transition-all duration-500 ease-in-out"
              style={{ width: `${online_percent}%` }}
            ></div>
          </div>
        </div>
        
        <div>
          <div className="flex justify-between mb-1.5">
            <span className="text-sm font-medium text-slate-700">Offline Devices</span>
            <div className="flex items-center">
              <span className="text-sm font-semibold text-rose-600">
                {offline_percent}%
              </span>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1 text-rose-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
              </svg>
            </div>
          </div>
          <div className="w-full h-2 bg-slate-100 rounded-full overflow-hidden">
            <div
              className="h-full bg-rose-500 rounded-full transition-all duration-500 ease-in-out"
              style={{ width: `${offline_percent}%` }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceStatus;
