import endpoints from '../endpoints';
import api from '../http';
import {
  CreateSwitchType,
  SwitchStateType,
  SwitchType,
} from '../util/apiTypes';

export const createSwitch = async (
  switchData: CreateSwitchType
): Promise<SwitchType> => {
  const url = endpoints.switches.switch.path;
  const { data } = await api.post<{ data: SwitchType }>(url, switchData);
  return data.data;
};

export const listSwitch = async (): Promise<SwitchType[]> => {
  const url = endpoints.switches.switch.path;
  const { data } = await api.get<{ data: SwitchType[] }>(url);
  return data.data;
};

export const getSwitchForDevice = async (
  deviceId: string
): Promise<SwitchType[]> => {
  let url = endpoints.switches.getSwitchForDevice.path;
  url = url.replace(':device_id', deviceId);
  const { data } = await api.get<{ data: SwitchType[] }>(url);
  return data.data;
};

export const updateSwitchState = async (switchStateData: SwitchStateType) => {
  let url = endpoints.switches.updateSwitchState.path;
  url = url
    .replace(':switch_id', switchStateData.switchId)
    .replace(':state', switchStateData.state);
  const { data } = await api.patch<{ data: SwitchType }>(url);
  return data.data;
};
