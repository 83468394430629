import endpoints from '../endpoints';
import api from '../http';
import {
  CreateSensorType,
  SensorForDeviceType,
  SensorType,
  SensorTypeType,
  SensorReadingChartType,
  SensorReadingRawType,
  SensorReadingAggregatedType,
  UpdateSensorType,
} from '../util/apiTypes';

export const getSensorForDevice = async (
  deviceId: string
): Promise<SensorForDeviceType[]> => {
  let url = endpoints.sensors.getSensorForDevice.path;
  url = url.replace(':device_id', deviceId);
  const { data } = await api.get<{ data: SensorForDeviceType[] }>(url);
  return data.data;
};

export const getSensorReadingChart = async (
  sensor_id: string,
  start: string,
  end: string,
  granularity: string
): Promise<SensorReadingChartType> => {
  let url = endpoints.sensors.getSensorReadingChart.path;
  url = url.replace(':sensor_id', sensor_id);
  url = `${url}?start=${start}&end=${end}&granularity=${granularity}`;

  if (granularity === 'raw') {
    const { data } = await api.get<{ data: SensorReadingRawType[] }>(url);
    return {
      type: 'raw',
      data: data.data,
    };
  } else if (granularity === 'hourly' || granularity === 'daily') {
    const { data } = await api.get<{ data: SensorReadingAggregatedType[] }>(
      url
    );
    return {
      type: 'aggregated',
      data: data.data,
    };
  } else {
    const { data } = await api.get<{ data: SensorReadingRawType[] }>(url);
    return {
      type: 'raw',
      data: data.data,
    };
  }
  // return data.data;
};

export const getSensorTypes = async (): Promise<SensorTypeType[]> => {
  const url = endpoints.sensors.sensorType.path;
  const { data } = await api.get(url);
  return data.data;
};

export const createSensor = async (
  sensorData: CreateSensorType
): Promise<SensorType> => {
  const url = endpoints.sensors.sensor.path;
  const { data } = await api.post<{ data: SensorType }>(url, sensorData);
  return data.data;
};

export const listSensor = async (): Promise<SensorForDeviceType[]> => {
  const url = endpoints.sensors.sensor.path;
  const { data } = await api.get(url);
  return data.data;
};

export const getSensor = async (
  sensorId: string
): Promise<SensorForDeviceType> => {
  const url = `${endpoints.sensors.sensor.path}/${sensorId}`;
  const { data } = await api.get(url);
  return data.data;
};

export const updateSensor = async (
  sensorId: string,
  sensorData: UpdateSensorType
): Promise<SensorForDeviceType> => {
  const url = `${endpoints.sensors.sensor.path}/${sensorId}`;
  const { data } = await api.put<{ data: SensorForDeviceType }>(url, sensorData);
  return data.data;
};
