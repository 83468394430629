import { DeviceType } from '../../api/util/apiTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faServer,
  faMicrochip,
  faPlug,
} from '@fortawesome/free-solid-svg-icons';

interface DeviceCardProps {
  device: DeviceType;
}

const DeviceListCard: React.FC<DeviceCardProps> = ({ device }) => {
  const isOnline = device?.status === 'Online';
  
  // Helper function to get device icon based on type
  const getDeviceIcon = (type: string) => {
    switch (type?.toLowerCase()) {
    case 'connected gateway device':
      return faServer;
    case 'connected node device':
      return faMicrochip;
    case 'local node device':
      return faMicrochip;
    default:
      return faPlug;
    }
  };
  
  // Helper function to get device type color
  const getDeviceTypeColor = (type: string) => {
    switch (type?.toLowerCase()) {
    case 'connected gateway device':
      return 'from-purple-50 to-indigo-50';
    case 'connected node device':
      return 'from-teal-50 to-emerald-50';
    case 'local node device':
      return 'from-amber-50 to-yellow-50';
    default:
      return 'from-slate-50 to-blue-50';
    }
  };
  
  // Helper function to get icon background color
  const getIconBgColor = (type: string) => {
    switch (type?.toLowerCase()) {
    case 'connected gateway device':
      return 'text-purple-500';
    case 'connected node device':
      return 'text-teal-500';
    case 'local node device':
      return 'text-amber-500';
    default:
      return 'text-slate-500';
    }
  };
  
  // Format the creation date if available
  const formattedDate = device.created_at 
    ? new Date(device.created_at).toLocaleDateString(undefined, { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric' 
    })
    : null;
    
  return (
    <div className={`relative bg-gradient-to-r ${getDeviceTypeColor(device.type)} rounded-xl shadow-sm overflow-hidden transition-all duration-300 hover:shadow-md group`}>
      <div className="absolute inset-0 bg-grid-slate-100 [mask-image:linear-gradient(0deg,#fff,rgba(255,255,255,0.6))] bg-opacity-20"></div>
      
      <div className="relative p-6">
        <div className="flex items-center justify-between mb-3">
          <div className="flex items-center">
            <div className={`p-2.5 rounded-lg ${getIconBgColor(device.type)} bg-white/80 shadow-sm mr-3`}>
              <FontAwesomeIcon 
                icon={getDeviceIcon(device.type)} 
                className="text-lg"
              />
            </div>
            <h3 className="font-semibold text-slate-800 group-hover:text-slate-900 transition-colors">
              {device.name ? device.name : 'Unnamed Device'}
            </h3>
          </div>
          
          <div className={`flex items-center px-2.5 py-1 rounded-full text-xs font-medium ${
            isOnline 
              ? 'bg-green-100 text-green-700' 
              : 'bg-red-100 text-red-700'
          }`}>
            <span className={`inline-block h-1.5 w-1.5 rounded-full mr-1.5 ${
              isOnline ? 'bg-green-500' : 'bg-red-500'
            }`}></span>
            {isOnline ? 'Online' : 'Offline'}
          </div>
        </div>
        
        <div className="flex flex-col space-y-1 mb-2">
          <div className="flex items-center text-sm text-slate-600">
            <span className="inline-block w-20 text-xs text-slate-500">Type:</span>
            <span className="font-medium">{device.type || 'Unknown'}</span>
          </div>
          
          {device.group_name && (
            <div className="flex items-center text-sm text-slate-600">
              <span className="inline-block w-20 text-xs text-slate-500">Group:</span>
              <span className="font-medium">{device.group_name}</span>
            </div>
          )}
          
          {formattedDate && (
            <div className="flex items-center text-sm text-slate-600">
              <span className="inline-block w-20 text-xs text-slate-500">Added:</span>
              <span>{formattedDate}</span>
            </div>
          )}
        </div>
      </div>
      
      <div className="absolute top-3 right-3 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
        <div className="p-1 rounded-full bg-white/80 shadow-sm text-slate-400 hover:text-sky-500 cursor-pointer transition-colors">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default DeviceListCard;
