import { useQuery } from '@tanstack/react-query';
import { getDeviceList } from '../../api/device/deviceApi';
import DeviceListCard from './DeviceListCard';
import Spinner from '../Spinner';
import { Link } from 'react-router-dom';

const DeviceList: React.FC = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['deviceList'],
    queryFn: getDeviceList,
  });

  if (isLoading) return <Spinner />;
  if (error instanceof Error)
    return <p className="text-center text-red-500">Error: {error.message}</p>;

  if (!data || data.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center py-12 bg-white rounded-xl shadow-sm border border-slate-200 text-center px-4">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-slate-300 mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z" />
        </svg>
        <h3 className="text-xl font-semibold text-slate-700 mb-2">No devices available</h3>
        <p className="text-slate-500 max-w-md mb-6">You need to add a device to a group first to start monitoring your IoT ecosystem</p>
        <Link
          to="/group"
          className="inline-flex items-center px-4 py-2 rounded-lg text-sm font-medium transition-colors bg-amber-500 text-white hover:bg-amber-600"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
          Go to Groups
        </Link>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {data.map((device) => (
        <Link key={device.id} to={`/device/${device.id}`}>
          <DeviceListCard key={device.id} device={device} />
        </Link>
      ))}
    </div>
  );
};

export default DeviceList;
