import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Link } from 'react-router-dom';
import Logo from '../components/Logo/Logo';
import { forgotPasswordApi } from '../api/user/userApi';
import { ForgotPasswordResponse } from '../api/util/apiTypes';

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { mutate, isPending, error } = useMutation<
    ForgotPasswordResponse,
    AxiosError,
    { email: string }
  >({
    mutationFn: forgotPasswordApi,
    onSuccess: () => {
      setIsSubmitted(true);
    },
    onError: (error: AxiosError) => {
      console.error('Password reset request failed:', error.message);
    },
  });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    mutate({ email });
  };

  if (isSubmitted) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="p-8 bg-white shadow-md rounded-lg max-w-md w-full">
          <div className="flex justify-center mb-6">
            <Logo />
          </div>
          <h2 className="text-2xl font-bold text-center mb-4">Check Your Email</h2>
          <p className="text-gray-700 mb-6 text-center">
            If an account exists with the email {email}, we&apos;ve sent instructions to reset your password.
            Please check your inbox and follow the link to reset your password.
          </p>
          <div className="flex justify-center">
            <Link
              to="/login"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              Return to Login
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <form
        onSubmit={handleSubmit}
        className="p-8 bg-white shadow-md rounded-lg max-w-md w-full"
      >
        <div className="flex justify-center mb-6">
          <Logo />
        </div>
        <h2 className="text-2xl font-bold text-center mb-4">Forgot Password</h2>
        <p className="text-gray-700 mb-6 text-center">
          Enter your email address and we&apos;ll send you a link to reset your password.
        </p>
        <div className="mb-6">
          <label
            htmlFor="email"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Your email
          </label>
          <input
            type="email"
            id="email"
            required
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <button
          type="submit"
          disabled={isPending}
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center"
        >
          {isPending ? 'Sending...' : 'Send Reset Link'}
        </button>
        {error && <p className="mt-2 text-sm text-red-600">{error.message}</p>}
        <div className="mt-4 text-sm font-medium text-center">
          <Link to="/login" className="text-blue-700 hover:underline">
            Back to Login
          </Link>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword; 