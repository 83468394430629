import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import Cookies from 'js-cookie';
import { LoginResponse } from '../api/util/apiTypes';
import { initializeAuthFunctions } from '../api/http';

interface AuthContextType {
  isAuthenticated: boolean;
  login: (data: LoginResponse) => void;
  logout: () => void;
  getAccessToken: () => string | null;
  getRefreshToken: () => string | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Set secure storage based on browser capabilities
const useSecureStorage = () => {
  const inMemoryTokens: { accessToken: string | null; refreshToken: string | null } = {
    accessToken: null,
    refreshToken: null,
  };

  // Check if we're in a secure context (HTTPS)
  const isSecureContext = window.isSecureContext;

  const setAccessToken = (token: string) => {
    if (isSecureContext) {
      // Use cookies with httpOnly if possible in production
      Cookies.set('access_token', token, { 
        expires: 1/24, // 1 hour
        secure: true,
        sameSite: 'strict'
      });
    } else {
      // Fallback to sessionStorage (cleared when browser is closed)
      sessionStorage.setItem('access_token', token);
    }
    // Keep a copy in memory for immediate access
    inMemoryTokens.accessToken = token;
  };

  const setRefreshToken = (token: string) => {
    if (isSecureContext) {
      // Use cookies with longer expiration
      Cookies.set('refresh_token', token, { 
        expires: 7, // 7 days
        secure: true,
        sameSite: 'strict'
      });
    } else {
      // If not in secure context, still use localStorage for refresh token
      // as it needs to persist between sessions
      localStorage.setItem('refresh_token', token);
    }
    inMemoryTokens.refreshToken = token;
  };

  const getAccessToken = (): string | null => {
    // Try memory first for performance
    if (inMemoryTokens.accessToken) return inMemoryTokens.accessToken;
    
    // Then try cookie
    const cookieToken = Cookies.get('access_token');
    if (cookieToken) return cookieToken;
    
    // Finally try sessionStorage
    return sessionStorage.getItem('access_token');
  };

  const getRefreshToken = (): string | null => {
    // Try memory first
    if (inMemoryTokens.refreshToken) return inMemoryTokens.refreshToken;
    
    // Then try cookie
    const cookieToken = Cookies.get('refresh_token');
    if (cookieToken) return cookieToken;
    
    // Finally try localStorage
    return localStorage.getItem('refresh_token');
  };

  const clearTokens = () => {
    // Clear cookies
    Cookies.remove('access_token');
    Cookies.remove('refresh_token');
    
    // Clear storage
    sessionStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    
    // Clear memory
    inMemoryTokens.accessToken = null;
    inMemoryTokens.refreshToken = null;
  };

  const setTokens = (accessToken: string, refreshToken: string) => {
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
  };

  return {
    setAccessToken,
    setRefreshToken,
    getAccessToken,
    getRefreshToken,
    clearTokens,
    setTokens,
  };
};

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const storage = useSecureStorage();

  // Initialize the auth functions in the HTTP client
  useEffect(() => {
    initializeAuthFunctions(
      storage.getAccessToken,
      storage.getRefreshToken,
      storage.clearTokens,
      storage.setTokens
    );
    // These functions from storage are stable and won't change during component lifecycle
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // On mount, check if user is authenticated
  useEffect(() => {
    const checkAuth = () => {
      const token = storage.getAccessToken();
      setIsAuthenticated(!!token);
    };

    checkAuth();
    // Listen for storage events from other tabs
    window.addEventListener('storage', checkAuth);
    return () => {
      window.removeEventListener('storage', checkAuth);
    };
    // storage reference is stable and won't change during component lifecycle
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = (data: LoginResponse) => {
    storage.setAccessToken(data.access_token);
    storage.setRefreshToken(data.refresh_token);
    setIsAuthenticated(true);
  };

  const logout = () => {
    storage.clearTokens();
    setIsAuthenticated(false);
  };

  const value = {
    isAuthenticated,
    login,
    logout,
    getAccessToken: storage.getAccessToken,
    getRefreshToken: storage.getRefreshToken,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}; 