import React, { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAirFreshener,
  faBoltLightning,
  faDoorOpen,
  faTemperatureHigh,
  faTint,
  faWalking,
  faWater,
  faChartLine,
  faInfoCircle,
  faHistory,
  faPencilAlt,
} from '@fortawesome/free-solid-svg-icons';
import { SensorReadingChartType, UpdateSensorType } from '../../api/util/apiTypes';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { getSensor, getSensorReadingChart, updateSensor } from '../../api/sensor/sensorApi';
import Spinner from '../Spinner';
import SensorChart from './SensorChart';
import { AxiosError } from 'axios';
import { Link } from 'react-router-dom';
// If you're using React Router to load detail by sensor ID:
// import { useParams } from 'react-router-dom';

const getSensorIcon = (type: string | undefined) => {
  if (!type) return faTemperatureHigh;
  switch (type) {
  case 'TEMPERATURE':
    return faTemperatureHigh;
  case 'HUMIDITY':
    return faTint;
  case 'MOISTURE':
    return faWater;
  case 'LIGHT':
    return faBoltLightning;
  case 'DOOR':
    return faDoorOpen;
  case 'MOTION':
    return faWalking;
  case 'CO2':
    return faAirFreshener;
  default:
    return faTemperatureHigh;
  }
};

const getSensorUnit = (unit: string) => {
  switch (unit) {
  case 'celsius':
    return '°C';
  case 'fahrenheit':
    return '°F';
  case 'percentage':
    return '%';
  case 'lumens':
    return 'lm';
  default:
    return unit;
  }
};

interface SensorDetailProps {
  // Option 1: directly pass the sensor data from the parent
  //   sensor: SensorForDeviceType;
  sensorId: string;
  // Option 2 (if fetching by sensor ID in this component):
  // no props needed, you'd use a useParams() or a query param to fetch sensor details
}

const SensorDetail: React.FC<SensorDetailProps> = ({ sensorId }) => {
  const [timeRange, setTimeRange] = useState('1h');
  const [granularity, setgranularity] = useState('raw');
  const [showSensorDetails, setShowSensorDetails] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [formData, setFormData] = useState<UpdateSensorType>({ name: '', description: '' });
  const [errors, setErrors] = useState({ name: '', description: '' });
  
  const queryClient = useQueryClient();
  
  const { data, isLoading, error } = useQuery({
    queryKey: ['getSensor', sensorId],
    queryFn: () => getSensor(sensorId),
  });
  const { name, type, value, unit, timestamp, status, description } = data ?? {};

  // Update mutation
  const updateSensorMutation = useMutation({
    mutationFn: (data: { id: string; data: UpdateSensorType }) => 
      updateSensor(data.id, data.data),
    onSuccess: () => {
      // Invalidate and refetch the sensor data
      queryClient.invalidateQueries({ queryKey: ['getSensor', sensorId] });
      // Close modal
      setIsEditModalOpen(false);
    },
  });

  const { startTime, endTime } = useMemo(() => {
    if (!data) return { startTime: undefined, endTime: undefined };

    const now = new Date();
    let duration = 0;

    switch (timeRange) {
    case '1h':
      duration = 1 * 60 * 60 * 1000; // 1 hour
      setgranularity('raw');
      break;
    case '6h':
      duration = 6 * 60 * 60 * 1000; // 6 hours
      setgranularity('raw');
      break;
    case '12h':
      duration = 12 * 60 * 60 * 1000; // 12 hours
      setgranularity('hourly');
      break;
    case '24h':
      duration = 24 * 60 * 60 * 1000; // 24 hours
      setgranularity('hourly');
      break;
    case '1m':
      duration = 30 * 24 * 60 * 60 * 1000; // 1 month (approx 30 days)
      setgranularity('hourly');
      break;
    case '3m':
      duration = 3 * 30 * 24 * 60 * 60 * 1000; // 3 months (approx 90 days)
      setgranularity('daily');
      break;
    default:
      duration = 6 * 60 * 60 * 1000; // Default to 6 hours
    }

    return {
      endTime: now.toISOString(),
      startTime: new Date(now.getTime() - duration).toISOString(),
    };
  }, [data, timeRange]);

  // SensorReading Chart API
  const { data: sensorReadingData } = useQuery<
    SensorReadingChartType,
    AxiosError
  >({
    queryKey: ['sensorReadingChart', sensorId, startTime, endTime],
    queryFn: () =>
      sensorId && startTime && endTime
        ? getSensorReadingChart(sensorId, startTime, endTime, granularity)
        : Promise.reject(new Error('No sensor ID available')),
    enabled: !!sensorId,
  });

  if (isLoading) return <Spinner />;
  if (error instanceof Error)
    return <p className="text-center text-red-500">Error: {error.message}</p>;

  let readableDate = '';
  if (timestamp) {
    const date = new Date(timestamp);
    readableDate = date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    });
  }

  const isOnline = status === 'Online';
  const toggleSensorDetails = () => setShowSensorDetails(prev => !prev);
  
  const handleTimeRangeChange = (range: string) => {
    setTimeRange(range);
  };

  // Helper function to get a color based on sensor type
  const getSensorTypeColor = (type: string | undefined) => {
    if (!type) return 'from-sky-50 to-indigo-50';
    
    switch (type) {
    case 'TEMPERATURE':
      return 'from-orange-50 to-amber-50';
    case 'HUMIDITY':
      return 'from-blue-50 to-cyan-50';
    case 'MOISTURE':
      return 'from-emerald-50 to-teal-50';
    case 'LIGHT':
      return 'from-yellow-50 to-amber-50';
    case 'DOOR':
      return 'from-indigo-50 to-violet-50';
    case 'MOTION':
      return 'from-fuchsia-50 to-pink-50';
    case 'CO2':
      return 'from-lime-50 to-emerald-50';
    default:
      return 'from-sky-50 to-indigo-50';
    }
  };
  
  // Helper function to get value color based on sensor type
  const getValueColor = (type: string | undefined) => {
    if (!type) return 'text-sky-500';
    
    switch (type) {
    case 'TEMPERATURE':
      return 'text-orange-500';
    case 'HUMIDITY':
      return 'text-blue-500';
    case 'MOISTURE':
      return 'text-emerald-500';
    case 'LIGHT':
      return 'text-yellow-500';
    case 'DOOR':
      return 'text-indigo-500';
    case 'MOTION':
      return 'text-fuchsia-500';
    case 'CO2':
      return 'text-lime-600';
    default:
      return 'text-sky-500';
    }
  };

  const timeRangeOptions = [
    { value: '1h', label: '1 Hour' },
    { value: '6h', label: '6 Hours' },
    { value: '12h', label: '12 Hours' },
    { value: '24h', label: '24 Hours' },
    { value: '1m', label: '1 Month' },
    { value: '3m', label: '3 Months' },
  ];

  // Open modal with current sensor data
  const openEditModal = () => {
    if (data) {
      setFormData({
        name: data.name || '',
        description: data.description || ''
      });
      setIsEditModalOpen(true);
    }
  };

  // Close modal
  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setErrors({ name: '', description: '' });
  };

  // Handle form input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    
    // Clear error when user types
    if (errors[name as keyof typeof errors]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  // Validate form before submission
  const validateForm = () => {
    let isValid = true;
    const newErrors = { name: '', description: '' };
    
    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
      isValid = false;
    } else if (formData.name.length > 100) {
      newErrors.name = 'Name must be 100 characters or less';
      isValid = false;
    }
    
    setErrors(newErrors);
    return isValid;
  };

  // Handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (validateForm()) {
      updateSensorMutation.mutate({
        id: sensorId,
        data: formData
      });
    }
  };

  return (
    <div className="max-w-6xl mx-auto px-4 py-6">
      {/* Hero section with sensor overview */}
      <div className={`relative mb-10 bg-gradient-to-r ${getSensorTypeColor(type)} rounded-2xl p-8 shadow-sm overflow-hidden`}>
        <div className="absolute inset-0 bg-grid-slate-100 [mask-image:linear-gradient(0deg,#fff,rgba(255,255,255,0.6))] bg-opacity-20"></div>
        
        <div className="relative flex items-start justify-between">
          <div>
            <div className="flex items-center gap-3">
              <h1 className="text-3xl font-bold text-slate-800">
                {name || 'Unnamed Sensor'}
              </h1>
              <div className={`flex items-center px-3 py-1 rounded-full text-sm font-medium ${
                isOnline 
                  ? 'bg-green-100 text-green-800' 
                  : 'bg-red-100 text-red-800'
              }`}>
                <span className={`inline-block h-2 w-2 rounded-full mr-2 ${
                  isOnline ? 'bg-green-500' : 'bg-red-500'
                }`}></span>
                {isOnline ? 'Online' : 'Offline'}
              </div>
              
              {/* Edit button */}
              <button
                onClick={openEditModal}
                className="inline-flex items-center px-2.5 py-1.5 rounded-lg text-sm bg-white border border-slate-200 text-slate-700 hover:bg-slate-50 hover:text-sky-600 transition-colors focus:outline-none focus:ring-2 focus:ring-sky-500/40"
              >
                <FontAwesomeIcon icon={faPencilAlt} className="mr-1" />
                Edit
              </button>
            </div>
            {description && (
              <p className="mt-2 text-slate-600 max-w-2xl">
                {description}
              </p>
            )}
            
            <div className="mt-3 flex gap-2">
              <button
                onClick={toggleSensorDetails}
                className="inline-flex items-center px-3.5 py-1.5 rounded-lg text-sm font-medium transition-colors bg-white border border-slate-200 text-slate-700 hover:bg-slate-50 hover:text-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-500/40"
              >
                <FontAwesomeIcon 
                  icon={faInfoCircle} 
                  className={`mr-1.5 transition-transform ${showSensorDetails ? 'rotate-180' : ''}`} 
                />
                {showSensorDetails ? 'Hide Details' : 'View Details'}
              </button>
              
              <Link 
                to="/sensor" 
                className="inline-flex items-center px-3.5 py-1.5 rounded-lg text-sm font-medium transition-colors bg-white border border-slate-200 text-slate-700 hover:bg-slate-50 hover:text-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-500/40"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                </svg>
                Back to Sensors
              </Link>
            </div>
          </div>
          
          <div className="hidden md:flex flex-col items-center justify-center p-6 bg-white/90 rounded-xl shadow-sm border border-slate-200/50">
            <div className="text-6xl font-bold mb-1 whitespace-nowrap flex items-baseline">
              <span className={`${getValueColor(type)}`}>
                {type === 'CO2' && typeof value === 'number'
                  ? Math.round(value)
                  : typeof value === 'number' ? value.toFixed(1) : value}
              </span>
              <span className="text-2xl text-slate-500 ml-1">
                {unit ? getSensorUnit(unit) : ''}
              </span>
            </div>
            <div className="text-sm text-slate-500">Current Reading</div>
          </div>
        </div>
      </div>
      
      {/* Mobile reading display - only visible on small screens */}
      <div className="md:hidden flex items-center justify-center p-6 mb-6 bg-white rounded-xl shadow-sm border border-slate-200">
        <div className="text-center">
          <div className="text-5xl font-bold mb-1 flex items-baseline justify-center">
            <span className={`${getValueColor(type)}`}>
              {type === 'CO2' && typeof value === 'number'
                ? Math.round(value)
                : typeof value === 'number' ? value.toFixed(1) : value}
            </span>
            <span className="text-xl text-slate-500 ml-1">
              {unit ? getSensorUnit(unit) : ''}
            </span>
          </div>
          <div className="text-sm text-slate-500">Current Reading</div>
        </div>
      </div>
      
      {/* Sensor details panel (collapsible) */}
      {showSensorDetails && (
        <div className="mb-8 bg-white rounded-xl shadow-sm border border-slate-200 overflow-hidden transition-all duration-300 ease-in-out">
          <div className="grid md:grid-cols-2 gap-6 p-6">
            <div>
              <h3 className="text-sm font-medium text-slate-500 mb-2">Sensor Information</h3>
              <div className="space-y-4">
                <div>
                  <div className="text-xs font-medium text-slate-500">ID</div>
                  <div className="text-slate-700 font-mono text-sm mt-0.5">{sensorId}</div>
                </div>
                <div>
                  <div className="text-xs font-medium text-slate-500">TYPE</div>
                  <div className="text-slate-700 font-medium mt-0.5">{type}</div>
                </div>
                <div>
                  <div className="text-xs font-medium text-slate-500">UNIT</div>
                  <div className="text-slate-700 mt-0.5">{unit ? `${unit} (${getSensorUnit(unit)})` : 'Not specified'}</div>
                </div>
                <div>
                  <div className="text-xs font-medium text-slate-500">LAST UPDATED</div>
                  <div className="text-slate-700 mt-0.5">{readableDate || 'Unknown'}</div>
                </div>
              </div>
            </div>
            
            <div>
              <h3 className="text-sm font-medium text-slate-500 mb-2">Technical Details</h3>
              <div className="p-4 bg-slate-50 rounded-lg border border-slate-200">
                <div className="space-y-3">
                  <div className="flex items-center">
                    <FontAwesomeIcon icon={getSensorIcon(type)} className="text-lg mr-3 text-slate-500" />
                    <div>
                      <div className="text-xs font-medium text-slate-500">SENSOR TYPE</div>
                      <div className="text-slate-700">{type || 'Unknown'}</div>
                    </div>
                  </div>
                  
                  <div className="flex items-center">
                    <FontAwesomeIcon icon={faHistory} className="text-lg mr-3 text-slate-500" />
                    <div>
                      <div className="text-xs font-medium text-slate-500">UPDATE FREQUENCY</div>
                      <div className="text-slate-700">Every 5 minutes</div>
                    </div>
                  </div>
                  
                  <div className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-3 text-slate-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                    </svg>
                    <div>
                      <div className="text-xs font-medium text-slate-500">STATUS</div>
                      <div className="flex items-center">
                        <span className="text-slate-700">{status}</span>
                        <span
                          className={`ml-2 rounded-full h-2 w-2 ${isOnline ? 'bg-green-500' : 'bg-red-500'}`}
                          title={isOnline ? 'Online' : 'Offline'}
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      
      {/* Chart Section */}
      <div className="mb-8 bg-white rounded-xl shadow-sm border border-slate-200 overflow-hidden">
        <div className="p-6 border-b border-slate-200">
          <div className="flex flex-wrap items-center justify-between gap-4">
            <h2 className="text-xl font-bold text-slate-800 flex items-center">
              <FontAwesomeIcon icon={faChartLine} className="h-5 w-5 mr-2 text-sky-500" />
              Sensor Data History
            </h2>
            
            <div className="flex flex-wrap items-center gap-2">
              {timeRangeOptions.map((option) => (
                <button
                  key={option.value}
                  onClick={() => handleTimeRangeChange(option.value)}
                  className={`px-3 py-1.5 rounded-lg text-sm font-medium transition-colors ${
                    timeRange === option.value
                      ? 'bg-sky-100 text-sky-700 border border-sky-200'
                      : 'bg-white border border-slate-200 text-slate-600 hover:bg-slate-50'
                  }`}
                >
                  {option.label}
                </button>
              ))}
            </div>
          </div>
        </div>
        
        <div className="p-6">
          {sensorReadingData ? (
            <div className="h-[400px]">
              <SensorChart data={sensorReadingData} granularity={granularity} />
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center h-[300px] bg-slate-50 rounded-lg border border-dashed border-slate-200">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-slate-400 mb-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
              </svg>
              <p className="text-slate-500 font-medium">No data available for the selected time range</p>
              <p className="text-slate-400 text-sm mt-1">Try selecting a different time period</p>
            </div>
          )}
        </div>
      </div>
      
      {/* Additional Actions Section */}
      <div className="mt-6 pt-6 border-t border-slate-200">
        <div className="flex flex-wrap items-center gap-3">
          <button
            className="inline-flex items-center px-4 py-2 rounded-lg text-sm font-medium bg-white border border-slate-200 text-slate-700 hover:bg-slate-50 hover:text-sky-600 transition-colors"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
            </svg>
            Set Alerts
          </button>
          
          <button
            className="inline-flex items-center px-4 py-2 rounded-lg text-sm font-medium bg-white border border-slate-200 text-slate-700 hover:bg-slate-50 hover:text-sky-600 transition-colors"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
            </svg>
            Export Data
          </button>
          
          <button
            className="inline-flex items-center px-4 py-2 rounded-lg text-sm font-medium bg-white border border-red-200 text-red-600 hover:bg-red-50 transition-colors"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
            </svg>
            View Logs
          </button>
        </div>
      </div>
      
      {/* Edit Modal */}
      {isEditModalOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            {/* Background overlay */}
            <div 
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" 
              onClick={closeEditModal}
            ></div>
            
            {/* Modal panel */}
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                      Edit Sensor Details
                    </h3>
                    
                    <form onSubmit={handleSubmit}>
                      <div className="mb-4">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                          Name <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          value={formData.name}
                          onChange={handleInputChange}
                          maxLength={100}
                          className={`w-full px-3 py-2 border ${errors.name ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500`}
                        />
                        {errors.name && (
                          <p className="mt-1 text-sm text-red-600">{errors.name}</p>
                        )}
                        <p className="mt-1 text-xs text-gray-500">
                          {formData.name.length}/100 characters
                        </p>
                      </div>
                      
                      <div className="mb-4">
                        <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
                          Description
                        </label>
                        <textarea
                          id="description"
                          name="description"
                          value={formData.description}
                          onChange={handleInputChange}
                          rows={4}
                          className={`w-full px-3 py-2 border ${errors.description ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500`}
                        ></textarea>
                        {errors.description && (
                          <p className="mt-1 text-sm text-red-600">{errors.description}</p>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={handleSubmit}
                  disabled={updateSensorMutation.isPending}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sky-600 text-base font-medium text-white hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-50"
                >
                  {updateSensorMutation.isPending ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Saving...
                    </>
                  ) : (
                    'Save Changes'
                  )}
                </button>
                <button
                  type="button"
                  onClick={closeEditModal}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SensorDetail;
