import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../store/hooks';
import { showNotification } from '../../features/notificationSlice';

interface Column {
  header: string;
  key: string;
  link?: boolean;
  linkPath?: string;
}

interface TableConfig {
  addButtonTitle: string;
  addNewRoute?: string;
  baseRoute?: string;
  addDeleteMessage?: string;
  deleteApi: (id: string) => Promise<void>;
}

interface GenericTableProps {
  data: Array<{ [key: string]: string | number }>;
  columns: Column[];
  config: TableConfig;
}

const GenericTable: React.FC<GenericTableProps> = ({
  data,
  columns,
  config,
}) => {
  const dispatch = useAppDispatch();
  const handleShowError = (error: string) => {
    dispatch(
      showNotification({ type: 'error', message: error, duration: 3000 })
    );
  };

  const handleShowSuccess = (message: string) => {
    dispatch(
      showNotification({ type: 'success', message: message, duration: 3000 })
    );
  };

  const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set());
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const {
    addButtonTitle,
    baseRoute,
    addDeleteMessage,
    deleteApi,
    addNewRoute,
  } = config;

  const location = useLocation();

  const handleSelectRow = (id: string) => {
    setSelectedRows((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  };
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: deleteApi,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tableData'] });
      handleShowSuccess(
        addDeleteMessage ? addDeleteMessage : 'Record Deleted successfully!'
      );
    },
    onError: (error) => {
      handleShowError(error.message);
    },
  });

  const handleDeleteSelected = () => {
    if (
      window.confirm('Are you sure you want to delete the selected records?')
    ) {
      const selectedId = Array.from(selectedRows)[0];
      mutation.mutate(selectedId);
      setSelectedRows(new Set());
      setDropdownVisible(!dropdownVisible);
    }
  };
  const handleEditSelected = () => {
    const selectedId = Array.from(selectedRows)[0];
    navigate(`${baseRoute ? baseRoute : location.pathname}/edit/${selectedId}`);
  };

  const handleAddNew = () => {
    const route = addNewRoute
      ? addNewRoute
      : `${baseRoute ? baseRoute : location.pathname}/add-new`;
    navigate(route);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-items-start items-center mb-4 gap-4">
        <button
          className="bg-sky-500 hover:bg-sky-600 text-white px-4 py-2 rounded"
          onClick={handleAddNew}
        >
          {addButtonTitle ? addButtonTitle : 'Add Item'}
        </button>

        <div className="relative" ref={dropdownRef}>
          <button
            className="px-4 py-2 rounded text-white bg-gray-500 hover:bg-gray-600"
            onClick={() => setDropdownVisible(!dropdownVisible)}
          >
            Actions
          </button>

          {dropdownVisible && (
            <div className="absolute mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
              <div className="py-1">
                <button
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                  onClick={handleEditSelected}
                  disabled={selectedRows.size === 0}
                >
                  Edit
                </button>
                <button
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                  onClick={handleDeleteSelected}
                  disabled={selectedRows.size === 0}
                >
                  Delete
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b text-left">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    if (e.target.checked) {
                      const selectedIds = new Set(
                        data
                          ?.map((row) => row.id)
                          .filter((id) => typeof id === 'string') as string[]
                      );
                      setSelectedRows(selectedIds);
                    } else {
                      setSelectedRows(new Set());
                    }
                  }}
                  checked={selectedRows.size === data?.length}
                />
              </th>
              {columns.map((column) => (
                <th
                  key={column.key}
                  scope="col"
                  className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b"
                >
                  {column.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td className="py-2 px-4 border-b text-left">
                  <input
                    type="checkbox"
                    checked={
                      typeof row.id === 'string' && selectedRows.has(row.id)
                    }
                    onChange={() => {
                      if (typeof row.id === 'string') handleSelectRow(row.id);
                    }}
                  />
                </td>

                {columns.map((column) => (
                  <td key={column.key} className="py-2 px-4 border-b text-left">
                    {column.link ? (
                      <Link
                        to={`${baseRoute ? baseRoute : location.pathname}/${row.id}`}
                        className="text-sky-500 hover:underline"
                      >
                        {row[column.key]}
                      </Link>
                    ) : (
                      row[column.key]
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GenericTable;
