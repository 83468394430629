import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import GroupDeleteModal from './GroupDeleteModal';

interface Group {
  id: string;
  name: string;
  description: string;
  link?: string;
}

interface GroupTableProps {
  groups: Group[];
  onEdit: (data: { id: string; name: string; description: string }) => void;
}

const GroupTable: React.FC<GroupTableProps> = ({ groups, onEdit }) => {
  // Track which group's modal is open
  const [modalGroupId, setModalGroupId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (groupId: string) => {
    setModalGroupId(groupId);
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    setIsModalOpen(false);
    setModalGroupId(null);
  };

  return (
    <div className="overflow-hidden">
      <table className="min-w-full">
        <thead>
          <tr>
            <th className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-slate-500 border-b border-slate-200">
              Group Name
            </th>
            <th className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-slate-500 border-b border-slate-200">
              Description
            </th>
            <th className="py-3 px-6 text-right text-xs font-medium uppercase tracking-wider text-slate-500 border-b border-slate-200">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-slate-100">
          {groups.map((group) => (
            <tr 
              key={group.id} 
              className="hover:bg-slate-50 transition-colors duration-150"
            >
              <td className="py-4 px-6">
                {group.link ? (
                  <Link
                    to={group.link}
                    className="font-medium text-indigo-600 hover:text-indigo-800 transition-colors duration-150"
                  >
                    {group.name}
                  </Link>
                ) : (
                  <span className="font-medium text-slate-700">{group.name}</span>
                )}
              </td>
              <td className="py-4 px-6 text-slate-600 max-w-md truncate">
                {group.description || <span className="text-slate-400 italic">No description</span>}
              </td>
              <td className="py-4 px-6 text-right whitespace-nowrap">
                <div className="flex items-center justify-end space-x-3">
                  <button
                    onClick={() => onEdit({
                      id: group.id,
                      name: group.name,
                      description: group.description,
                    })}
                    className="text-slate-600 hover:text-indigo-600 transition-colors duration-150"
                    title="Edit group"
                  >
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      className="h-5 w-5" 
                      fill="none" 
                      viewBox="0 0 24 24" 
                      stroke="currentColor"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={2} 
                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" 
                      />
                    </svg>
                  </button>
                  <button
                    onClick={() => openModal(group.id)}
                    className="text-slate-600 hover:text-red-600 transition-colors duration-150"
                    title="Delete group"
                  >
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      className="h-5 w-5" 
                      fill="none" 
                      viewBox="0 0 24 24" 
                      stroke="currentColor"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={2} 
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" 
                      />
                    </svg>
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      
      {/* Only render one modal that shows for the selected group */}
      {modalGroupId && (
        <GroupDeleteModal
          isOpen={isModalOpen}
          onClose={closeModal}
          groupId={modalGroupId}
          title="Delete Group"
        />
      )}
    </div>
  );
};

export default GroupTable;
