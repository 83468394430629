import endpoints from '../endpoints';
import api from '../http';
import { LoginResponse, SignupResponse, UserType, ForgotPasswordResponse, ResetPasswordResponse } from '../util/apiTypes';

export const getUser = async (): Promise<UserType> => {
  const url = endpoints.users.getUser.path;
  const { data } = await api.get<{ data: UserType }>(url);
  return data.data; // Ensure this matches your API response structure
};

export const loginApi = async (loginData: {
  email: string;
  password: string;
}) => {
  const url = endpoints.users.login.path;
  const response = await api.post<{ data: LoginResponse }>(url, loginData);
  return response.data.data;
};

export const signupApi = async (signupData: {
  email: string;
  password: string;
  business_name: string;
}) => {
  const url = endpoints.users.signup.path;
  const response = await api.post<{ data: SignupResponse }>(url, signupData);
  return response.data.data; // Adjust according to your actual API response structure
};

export const verifyEmailApi = async (tokenData: { token: string }) => {
  const url = endpoints.users.verifyEmail.path;
  const { data } = await api.post(url, tokenData);
  return data.data;
};

export const forgotPasswordApi = async (emailData: { email: string }) => {
  const url = endpoints.users.forgotPassword.path;
  const response = await api.post<{ data: ForgotPasswordResponse }>(url, emailData);
  return response.data.data;
};

export const resetPasswordApi = async (resetData: { 
  ticket: string; 
  password: string; 
  confirm_password: string;
}) => {
  const url = endpoints.users.resetPassword.path;
  const response = await api.post<{ data: ResetPasswordResponse }>(url, resetData);
  return response.data.data;
};
