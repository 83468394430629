import { useQuery } from '@tanstack/react-query';
import GroupBreadcrumb from './GroupBreadcrumb';
import GroupTable from './GroupTable';
import {
  getGroupChildrenAncestor,
  getGroupRoot,
} from '../../api/group/groupApi';
import Spinner from '../Spinner';
import { GroupDetailType, GroupType } from '../../api/util/apiTypes';
import { AxiosError } from 'axios';
import { Link, useParams } from 'react-router-dom';
import { useState } from 'react';
import GroupAddModal from './GroupAddModal';
import AddDeviceModal from '../device/AddDeviceModal';
import DeviceListCard from '../device/DeviceListCard';

const Group = () => {
  // Group Modal setup
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('Add Group');
  const [initialData, setInitialData] = useState<
    { id: string; name: string; description: string } | undefined
  >(undefined);

  const openGroupModal = (data?: {
    id: string;
    name: string;
    description: string;
  }) => {
    setInitialData(data);
    setModalTitle(data ? 'Update Group' : 'Add Group');
    setIsModalOpen(true);
  };

  const closeGroupModal = () => {
    setIsModalOpen(false);
    setInitialData(undefined);
  };

  const [isDeviceModalOpen, setIsDeviceModalOpen] = useState(false);
  const openDeviceModal = () => {
    setIsDeviceModalOpen(true);
  };
  const closeDeviceModal = () => {
    setIsDeviceModalOpen(false);
  };

  const { groupId } = useParams<{ groupId?: string }>();
  const breadcrumbItems: { label: string; link?: string }[] = [];
  const tableGroups: {
    id: string;
    name: string;
    description: string;
    link?: string;
  }[] = [];

  const {
    data: rootData,
    isLoading: rootLoading,
    error: rootError,
  } = useQuery<GroupType, AxiosError>({
    queryKey: ['groupRoot'],
    queryFn: getGroupRoot,
  });

  const rootId = rootData ? rootData.id : undefined;
  const currentGroupId: string | undefined = groupId ? groupId : rootId;

  const {
    data: groupDetail,
    isLoading: groupLoading,
    error: groupError,
  } = useQuery<GroupDetailType, AxiosError>({
    queryKey: ['childrenAncestors', currentGroupId],
    queryFn: () => getGroupChildrenAncestor(currentGroupId),
    enabled: !!currentGroupId,
  });

  if (rootLoading || groupLoading) return <Spinner />;
  if (rootError instanceof Error)
    return (
      <p className="text-center text-red-500">Error: {rootError.message}</p>
    );
  if (groupError instanceof Error)
    return (
      <p className="text-center text-red-500">Error: {groupError.message}</p>
    );

  const children = groupDetail?.children;
  const ancestors = groupDetail?.ancestors;
  const devices = groupDetail?.devices;

  // Get current group information (the last ancestor is the current group)
  const currentGroup = ancestors && ancestors.length > 0 
    ? ancestors[ancestors.length - 1] 
    : rootData; // fall back to root data if no ancestors

  children?.forEach((child) => {
    tableGroups.push({
      id: child.id,
      name: child.name,
      description: child.description,
      link: `/group/${child.id}`,
    });
  });

  ancestors?.forEach((ancestor) => {
    breadcrumbItems.push({
      label: ancestor.name,
      link: `/group/${ancestor.id}`,
    });
  });

  return (
    <div className="max-w-6xl mx-auto px-4 py-6">
      <GroupBreadcrumb items={breadcrumbItems} />
      
      {/* Hero section with group overview */}
      <div className="relative mb-10 bg-gradient-to-r from-indigo-50 to-purple-50 rounded-2xl p-8 shadow-sm overflow-hidden">
        <div className="absolute inset-0 bg-grid-slate-100 [mask-image:linear-gradient(0deg,#fff,rgba(255,255,255,0.6))] bg-opacity-20"></div>
        
        <div className="relative flex flex-col md:flex-row justify-between">
          <div>
            <h1 className="text-3xl font-bold text-slate-800 flex items-center">
              {currentGroup?.id === rootId ? (
                <>
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    className="h-7 w-7 mr-2 text-indigo-500" 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor"
                  >
                    <path 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      strokeWidth={2} 
                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1h2" 
                    />
                  </svg>
                  Home
                </>
              ) : (
                currentGroup?.name || 'Group'
              )}
            </h1>
            {currentGroup?.description && (
              <p className="mt-2 text-slate-600 max-w-2xl">
                {currentGroup.description}
              </p>
            )}
            
            <div className="mt-4 flex flex-wrap gap-3">
              <button
                className="inline-flex items-center px-4 py-2 rounded-lg text-sm font-medium transition-colors bg-sky-600 text-white hover:bg-sky-700 shadow-sm"
                onClick={() => openGroupModal()}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
                Add Group
              </button>
              <button
                className="inline-flex items-center px-4 py-2 rounded-lg text-sm font-medium transition-colors bg-amber-500 text-white hover:bg-amber-600 shadow-sm"
                onClick={openDeviceModal}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
                Add Device
              </button>
            </div>
          </div>
          
          <div className="mt-6 md:mt-0 flex items-center">
            <div className="bg-white/90 border border-slate-200/50 rounded-xl p-4 shadow-sm">
              <div className="text-sm text-slate-500">Group Stats</div>
              <div className="mt-2 grid grid-cols-2 gap-4">
                <div className="text-center">
                  <div className="text-2xl font-bold text-indigo-600">{children?.length || 0}</div>
                  <div className="text-xs text-slate-500">Sub-groups</div>
                </div>
                <div className="text-center">
                  <div className="text-2xl font-bold text-amber-600">{devices?.length || 0}</div>
                  <div className="text-xs text-slate-500">Devices</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Sub-groups Section */}
      {tableGroups.length > 0 && (
        <div className="mb-8">
          <div className="flex items-center justify-between mb-5">
            <h2 className="text-xl font-bold text-slate-800 flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-indigo-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
              </svg>
              Sub-groups
              <span className="ml-2 px-2 py-0.5 text-xs font-medium rounded-full bg-indigo-100 text-indigo-700">
                {tableGroups.length}
              </span>
            </h2>
          </div>
          <div className="bg-white rounded-xl shadow-sm border border-slate-200 overflow-hidden">
            <GroupTable groups={tableGroups} onEdit={openGroupModal} />
          </div>
        </div>
      )}

      {/* Devices Section */}
      {devices && devices.length > 0 && (
        <div className="mb-8">
          <div className="flex items-center justify-between mb-5">
            <h2 className="text-xl font-bold text-slate-800 flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-amber-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z" />
              </svg>
              Devices
              <span className="ml-2 px-2 py-0.5 text-xs font-medium rounded-full bg-amber-100 text-amber-700">
                {devices.length}
              </span>
            </h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
            {devices.map((device) => (
              <Link key={device.id} to={`/device/${device.id}`} className="group transform transition-transform hover:scale-[1.01]">
                <DeviceListCard key={device.id} device={device} />
              </Link>
            ))}
          </div>
        </div>
      )}
      
      {/* Empty states */}
      {tableGroups.length === 0 && devices?.length === 0 && (
        <div className="flex flex-col items-center justify-center py-12 bg-white rounded-xl shadow-sm border border-slate-200 text-center px-4">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-slate-300 mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
          </svg>
          <h3 className="text-xl font-semibold text-slate-700 mb-2">This group is empty</h3>
          <p className="text-slate-500 max-w-md mb-6">Start by adding your first sub-group or device to organize your IoT ecosystem</p>
          <div className="flex flex-wrap gap-3 justify-center">
            <button
              onClick={() => openGroupModal()}
              className="inline-flex items-center px-4 py-2 rounded-lg text-sm font-medium transition-colors bg-sky-600 text-white hover:bg-sky-700"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
              </svg>
              Add Group
            </button>
            <button
              onClick={openDeviceModal}
              className="inline-flex items-center px-4 py-2 rounded-lg text-sm font-medium transition-colors bg-amber-500 text-white hover:bg-amber-600"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
              </svg>
              Add Device
            </button>
          </div>
        </div>
      )}

      <GroupAddModal
        isOpen={isModalOpen}
        onClose={closeGroupModal}
        title={modalTitle}
        parent={currentGroupId ?? ''}
        initialData={initialData}
      />
      <AddDeviceModal
        isOpen={isDeviceModalOpen}
        onClose={closeDeviceModal}
        group={currentGroupId ?? ''}
      />
    </div>
  );
};

export default Group;
