import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { deleteRule, listRule } from '../../api/rule/ruleApi';
import Spinner from '../Spinner';
import { listSensor } from '../../api/sensor/sensorApi';
import GenericTable from '../generic/GenericTable';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faList, 
  faFilter,
  faSort
} from '@fortawesome/free-solid-svg-icons';

const config = {
  addButtonTitle: 'Add Rule',
  addNewRoute: '/rule/add-new',
  baseRoute: '/rule',
  deleteApi: deleteRule,
  editable: true,
  deletable: true,
};

const Rule: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  
  const toggleFilters = () => setShowFilters(prev => !prev);

  const {
    data: sensors,
    isLoading: isLoadingSensors,
    isError: isErrorSensors,
    error: sensorsError,
  } = useQuery({
    queryFn: listSensor,
    queryKey: ['listSensors'],
  });

  const {
    data: rules,
    isLoading,
    isError,
    error: rulesError,
  } = useQuery({
    queryFn: listRule,
    queryKey: ['tableData'],
  });

  const getSensorName = (sensorId: string | undefined): string => {
    if (!sensorId) return 'Unknown Sensor';
    const sensor = sensors?.find((sensor) => sensor.id === sensorId);
    return sensor ? sensor.name : 'Unknown Sensor';
  };

  const getCondition = (condition: string | undefined): string => {
    switch (condition) {
    case 'gt':
      return 'Greater than';
    case 'lt':
      return 'Less than';
    default:
      return '';
    }
  };

  const ruleColumns = [
    { header: 'Name', key: 'name', link: true, linkPath: '/details' },
    { header: 'Sensor', key: 'sensor' },
    { header: 'Condition', key: 'condition' },
    { header: 'Threshold Value', key: 'threshold_value' },
    { header: 'Triggers', key: 'trigger_count' },
    { header: 'Actions', key: 'actions' },
  ];

  const tableData = rules
    ?.filter((rule) => rule !== null && rule !== undefined)
    .filter((rule) => 
      searchTerm === '' || 
      rule.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      getSensorName(rule.sensor).toLowerCase().includes(searchTerm.toLowerCase())
    )
    .map((rule) => ({
      ...rule,
      condition: getCondition(rule.condition),
      sensor: getSensorName(rule.sensor),
      switch: rule.switch ?? '',
    }));

  if (isLoading || isLoadingSensors) return <Spinner />;
  
  if (isError || isErrorSensors) {
    const errorMessage = isError 
      ? (rulesError as Error)?.message || 'Failed to load rules' 
      : (sensorsError as Error)?.message || 'Failed to load sensors';
    
    return (
      <div className="flex justify-center items-center p-4">
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{errorMessage}</span>
          <p className="mt-2 text-sm">Please try again or contact support if the problem persists.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto px-4 py-6">
      {/* Hero section */}
      <div className="relative mb-10 bg-gradient-to-r from-amber-50 to-orange-50 rounded-2xl p-8 shadow-sm overflow-hidden">
        <div className="absolute inset-0 bg-grid-slate-100 [mask-image:linear-gradient(0deg,#fff,rgba(255,255,255,0.6))] bg-opacity-20"></div>
        
        <div className="relative flex flex-col md:flex-row justify-between items-start md:items-center">
          <div>
            <h1 className="text-3xl font-bold text-slate-800 flex items-center">
              <FontAwesomeIcon icon={faList} className="mr-3 text-amber-500" />
              Rules
            </h1>
            <p className="mt-2 text-slate-600 max-w-2xl">
              Create and manage rules to automate actions based on sensor data.
            </p>
          </div>
          
          <div className="mt-4 md:mt-0">
            <Link 
              to="/rule/add-new" 
              className="px-4 py-2 bg-amber-500 text-white rounded-lg hover:bg-amber-600 transition shadow-sm flex items-center"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
              </svg>
              Add Rule
            </Link>
          </div>
        </div>
      </div>
      
      {/* Controls section */}
      <div className="mb-6">
        <div className="flex flex-col sm:flex-row justify-between gap-4">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
              </svg>
            </div>
            <input
              type="text"
              placeholder="Search rules..."
              className="pl-10 pr-4 py-2 w-full sm:w-72 border border-gray-300 rounded-lg focus:ring-amber-500 focus:border-amber-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          
          <div className="flex items-center gap-2">
            <button
              onClick={toggleFilters}
              className="inline-flex items-center px-3.5 py-2 rounded-lg text-sm font-medium transition-colors bg-white border border-slate-200 text-slate-700 hover:bg-slate-50 hover:text-amber-600"
            >
              <FontAwesomeIcon icon={faFilter} className="mr-1.5" />
              Filters
            </button>
            
            <button
              className="inline-flex items-center px-3.5 py-2 rounded-lg text-sm font-medium transition-colors bg-white border border-slate-200 text-slate-700 hover:bg-slate-50 hover:text-amber-600"
            >
              <FontAwesomeIcon icon={faSort} className="mr-1.5" />
              Sort
            </button>
          </div>
        </div>
        
        {showFilters && (
          <div className="mt-4 p-4 bg-white rounded-lg border border-slate-200 shadow-sm">
            <h3 className="text-sm font-medium text-slate-700 mb-3">Filter Rules</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <label className="block text-xs font-medium text-slate-500 mb-1">Sensor</label>
                <select className="w-full border border-slate-300 rounded-md p-2 text-sm">
                  <option value="">All Sensors</option>
                  {sensors?.map(sensor => (
                    <option key={sensor.id} value={sensor.id}>{sensor.name}</option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-xs font-medium text-slate-500 mb-1">Condition</label>
                <select className="w-full border border-slate-300 rounded-md p-2 text-sm">
                  <option value="">All Conditions</option>
                  <option value="gt">Greater than</option>
                  <option value="lt">Less than</option>
                </select>
              </div>
              <div>
                <label className="block text-xs font-medium text-slate-500 mb-1">Status</label>
                <select className="w-full border border-slate-300 rounded-md p-2 text-sm">
                  <option value="">All Status</option>
                  <option value="active">Active</option>
                  <option value="disabled">Disabled</option>
                </select>
              </div>
            </div>
            <div className="mt-4 flex justify-end gap-2">
              <button className="px-3 py-1.5 text-sm text-slate-600 hover:text-slate-800">
                Clear Filters
              </button>
              <button className="px-3 py-1.5 text-sm bg-amber-100 text-amber-700 rounded hover:bg-amber-200">
                Apply Filters
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Table section */}
      <div className="bg-white rounded-xl shadow-sm border border-slate-200 overflow-hidden">
        {tableData && tableData.length > 0 ? (
          <div className="overflow-x-auto">
            <GenericTable data={tableData} columns={ruleColumns} config={config} />
          </div>
        ) : (
          <div className="text-center p-12">
            <svg 
              className="mx-auto h-16 w-16 text-slate-300" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor" 
              aria-hidden="true"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={1.5} 
                d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" 
              />
            </svg>
            <h2 className="mt-4 text-xl font-semibold text-slate-700">No Rules to Display</h2>
            <p className="mt-2 text-slate-500 max-w-md mx-auto">
              Create rules to automate actions based on sensor data and thresholds.
            </p>
            <div className="mt-6">
              <Link 
                to="/rule/add-new" 
                className="inline-flex items-center px-4 py-2 bg-amber-500 text-white rounded-lg hover:bg-amber-600 transition shadow-sm"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
                Add Your First Rule
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Rule;
