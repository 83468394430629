import React, { useState } from 'react';
import { useAppDispatch } from '../../store/hooks';
import { showNotification } from '../../features/notificationSlice';
import { CreateDeviceType } from '../../api/util/apiTypes';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createDevice, getDeviceTypes } from '../../api/device/deviceApi';
import { useNavigate } from 'react-router-dom';
import Spinner from '../Spinner';

const CreateDeviceForm: React.FC = () => {
  // Notification related setup
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleShowError = (error: string) => {
    dispatch(
      showNotification({ type: 'error', message: error, duration: 3000 })
    );
  };

  const handleShowSuccess = () => {
    dispatch(
      showNotification({
        type: 'success',
        message: 'New device created successfully!',
        duration: 3000,
      })
    );
  };

  // Create device setup

  const { mutate, isPending } = useMutation({
    mutationFn: createDevice,
    onSuccess: (data) => {
      handleShowSuccess();
      navigate(`/device/${data.id}`);
    },
    onError: (error) => {
      handleShowError(error.message);
    },
  });
  const [device, setDevice] = useState<CreateDeviceType>({
    name: '',
    type: '',
    description: '',
  });

  // Get device tyoes
  const { data, isLoading, isError } = useQuery({
    queryFn: getDeviceTypes,
    queryKey: ['deviceTypes'],
  });

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setDevice({ ...device, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission logic here
    mutate(device);
  };

  if (isLoading || isPending) return <Spinner />;
  if (isError)
    return (
      <h2 className="flex justify-center  text-red-600">
        API Error, Try again.
      </h2>
    );

  return (
    <form
      className="max-w-md mx-auto p-4 bg-white rounded shadow-md"
      onSubmit={handleSubmit}
    >
      <div className="mb-4">
        <label htmlFor="name" className="block text-gray-700">
          Name
        </label>
        <input
          type="text"
          id="name"
          name="name"
          value={device.name}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded"
          required
        />
      </div>
      <div className="mb-4">
        <label htmlFor="type" className="block text-gray-700">
          Type
        </label>
        <select
          id="type"
          name="type"
          value={device.type}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded"
          required
        >
          <option value="" disabled>
            Select device type
          </option>
          {data?.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <label htmlFor="description" className="block text-gray-700">
          Description
        </label>
        <textarea
          id="description"
          name="description"
          value={device.description}
          onChange={handleChange}
          className="w-full px-3 py-2 border border-gray-300 rounded"
          required
        />
      </div>
      <div className="flex justify-end">
        <button
          type="submit"
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default CreateDeviceForm;
