import { useParams } from 'react-router-dom';
import SensorDetail from '../components/sensor/SensorDetail';
import SensorList from '../components/sensor/SensorList';

const SensorPage: React.FC = () => {
  const { sensorId } = useParams<{ sensorId?: string }>(); // Get deviceId from route parameters
  return (
    <div className="p-3">
      {sensorId ? <SensorDetail sensorId={sensorId} /> : <SensorList />}
      {/* <SensorList /> */}
    </div>
  );
};
export default SensorPage;
