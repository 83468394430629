import endpoints from '../endpoints';
import api from '../http';
import { TriggerType } from '../util/apiTypes';

export const listTriggerRule = async (
  rule_id: string
): Promise<TriggerType[]> => {
  let url = endpoints.triggers.triggerRule.path;
  url = rule_id ? `${url}/${rule_id}` : url;
  const { data } = await api.get<{ data: TriggerType[] }>(url);
  return data.data;
};

export const createTrigger = async (
  triggerData: object
): Promise<TriggerType> => {
  const url = endpoints.triggers.trigger.path;
  const { data } = await api.post<{ data: TriggerType }>(url, triggerData);
  return data.data;
};

export const getTrigger = async (triggerId: string): Promise<TriggerType> => {
  let url = endpoints.triggers.trigger.path;
  url = `${url}/${triggerId}`;
  const { data } = await api.get<{ data: TriggerType }>(url);
  return data.data;
};

export const updateTrigger = async (
  triggerData: object,
  triggerId: string
): Promise<TriggerType> => {
  let url = endpoints.triggers.trigger.path;
  url = `${url}/${triggerId}`;
  const { data } = await api.put<{ data: TriggerType }>(url, triggerData);
  return data.data;
};

export const deleteTrigger = async (triggerId: string) => {
  let url = endpoints.triggers.trigger.path;
  url = `${url}/${triggerId}`;
  const { data } = await api.delete(url);
  return data.data;
};
